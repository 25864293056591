import { CorporateActionDetails } from "../types/CompanyProfile";
import { CustomResponse } from "../types/Grant";
import { CompanyProfileDetails } from "../types/SiteSettings";
import optionsApi from "./optionsApi";

export async function getCompanyProfileDetails(): Promise<
  CustomResponse<CompanyProfileDetails>
> {
  return optionsApi.get(`v1/company/details`).then((res) => res.data);
}

export async function updateCompanyProfile(
  companyProfileDetails: CompanyProfileDetails
): Promise<any> {
  return optionsApi
    .post("v1/company/updateProfile", companyProfileDetails)
    .then((res) => res.data);
}

export async function createEsopCompany(createJson: any): Promise<string> {
  return optionsApi
    .post(`v1/company/create`, createJson)
    .then((res) => res.data);
}

export async function getAllCorporateActions(companyId: string): Promise<{
  corporateActions: CorporateActionDetails[];
  userHasLimitedAccessToCompany: boolean;
}> {
  return optionsApi.get(`v1/corporateAction/all`).then((res) => res.data.data);
}

export async function generateBonusReport(
  corporateActionDetails: CorporateActionDetails
): Promise<{ base64String: string }> {
  return optionsApi
    .post(`v1/corporateAction/generateBonusReport`, {
      corporateActionDetails,
    })
    .then((res) => res.data.data);
}

export async function approveBonus(
  corporateActionId: string
): Promise<{ base64String: string }> {
  return optionsApi
    .post(`v1/corporateAction/approveBonus`, {
      corporateActionId,
    })
    .then((res) => res.data.data);
}

export async function discardCorporateAction(
  corporateActionId: string
): Promise<{ base64String: string }> {
  return optionsApi
    .delete(`v1/corporateAction/discard?corporateActionId=${corporateActionId}`)
    .then((res) => res.data.data);
}

export async function uploadDocumentTemplate(documentDetails: {
  file: Blob;
  key: string;
  contentType: string;
  templateName: string;
  companyId: string;
  templateType: string;
  entityType: string;
  entityId: string;
}): Promise<{ base64String: string }> {
  const formData = new FormData();
  formData.append("file", documentDetails.file, documentDetails.templateName);
  formData.append("key", documentDetails.key);
  formData.append("contentType", documentDetails.contentType);
  formData.append("templateName", documentDetails.templateName);
  formData.append("companyId", documentDetails.companyId);
  formData.append("templateType", documentDetails.templateType);
  formData.append("entityType", documentDetails.entityType);
  formData.append("entityId", documentDetails.entityId);
  return optionsApi
    .post(`v1/template/documentTemplate`, formData)
    .then((res) => res.data.data);
}
