import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { MultiSelect, OptionTypes } from "../../components/shared/MultiSelect";
import { Select } from "../../components/shared/Select";
import { SwitchButton } from "../../components/shared/SwitchButton";
import Tooltip from "../../components/shared/Tooltip";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { useEmployeeDepartment } from "../../queries";
import {
  useCreateOptionBuyback,
  useEditLiquidityEvent,
  useGetOptionBuyback,
  useUploadEmployeeOffers,
} from "../../queries/optionBuyback";
import {
  EventType,
  LiquidityState,
  MonetaryEventState,
  MonetaryTransactionType,
  OptionBuyBackDetails,
  RecurringIntervalUnit,
  baseParticipationConstraints,
} from "../../types/OptionBuyback";
import convertToBase64 from "../../utils/convertToBase64";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import { useQuery } from "../../utils/useQuery";
import StatusLabel from "../esopOverview/StatusLabel";
import {
  convertBase64ToBlob,
  downloadBlobObject,
  downloadS3File,
} from "../../utils/DownloadFile";
import { getOfferTemplate } from "../../api/Esop";
import { useCompanyStore } from "../../store";
import FileInput from "../../components/shared/FileInput";

function CreateBuybackDetails() {
  const { id } = useParams();
  const { companyData } = useCompanyStore();
  const isUserEsopViewer = isEsopViewer();
  const currencySymbol = getCurrencySymbol();
  const query = useQuery();
  const searchParam = query.get("mode");
  const _id = id ?? "";
  const navigate = useNavigate();
  const { data: departments } = useEmployeeDepartment();
  const deprt: OptionTypes[] = [];
  (departments || []).forEach((department) => {
    deprt.push({ value: department.toLowerCase() });
  });
  const { data: optionBuybackOverview } = useGetOptionBuyback();
  const [buybackFileName, setBuybackFileName] = useState("");
  let optionBuybackList: OptionBuyBackDetails[] = [];
  const optionBuyback = optionBuybackOverview?.filter(
    (list) => (list?.id || "") === _id
  );
  optionBuybackList = optionBuyback || [];
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  function createInitialValues(): OptionBuyBackDetails {
    return {
      id: "",
      eventName: "",
      description: "",
      eventPercentage: 0,
      recurringSetting: {
        isRecurring: false,
        recurringInterval: 0,
        recurringIntervalUnit: RecurringIntervalUnit.MONTH,
      },
      askWillingness: true,
      buyerName: "",
      createdAt: formatDate(new Date().toString()),
      eventState: MonetaryEventState.DRAFT,
      startDate: formatDate(new Date().toString()),
      endDate: formatDate(new Date().toString()),
      eventType: EventType.CASH,
      isDeleted: false,
      isOnAutoPilot: false,
      liquidityState: LiquidityState.DRAFT,
      monetaryDetails: {
        fmvPrice: 0,
        miscCharges: 0,
        price: 0,
        sellingPrice: 0,
        paymentLink: "",
        includeStrikePrice: true,
      },
      monetaryOffers: [],
      offerFilter: {
        department: [],
        minimumTenure: 0,
        minimumVestedOptions: 0,
        minimumWillingness: 0,
        minimumWillingnessPercentage: 0,
        participationConstraints: [],
      },
      transactionType: MonetaryTransactionType.BUYBACK,
      updatedAt: formatDate(new Date().toString()),
      vestingCutOffDate: formatDate(new Date().toString()),
      participantListMethod: "generate",
      buybackfile: "",
    };
  }

  function editInitialValues(): OptionBuyBackDetails {
    return {
      id: optionBuybackList[0]?.id,
      companyId: optionBuybackList[0]?.companyId,
      askWillingness: optionBuybackList[0]?.askWillingness,
      eventName: optionBuybackList[0]?.eventName,
      description: optionBuybackList[0]?.description,
      eventPercentage: (optionBuybackList[0]?.eventPercentage || 0) * 100,
      buyerName: optionBuybackList[0]?.buyerName,
      createdAt: optionBuybackList[0].createdAt,
      vestingCutOffDate: formatDate(
        new Date(
          optionBuybackList[0]?.vestingCutOffDate || "1970-01-01"
        ).toString()
      ),
      startDate: formatDate(
        new Date(optionBuybackList[0]?.startDate || "1970-01-01").toString()
      ),
      endDate: formatDate(
        new Date(optionBuybackList[0]?.endDate || "1970-01-01").toString()
      ),
      transactionType: optionBuybackList[0]?.transactionType,
      eventState: optionBuybackList[0]?.eventState,
      eventType: optionBuybackList[0]?.eventType,
      isDeleted: optionBuybackList[0]?.isDeleted,
      isOnAutoPilot: optionBuybackList[0]?.isOnAutoPilot,
      liquidityState: optionBuybackList[0]?.liquidityState,
      monetaryDetails: optionBuybackList[0]?.monetaryDetails,
      monetaryOffers: optionBuybackList[0]?.monetaryOffers,
      offerFilter: {
        ...optionBuybackList[0]?.offerFilter,
        minimumWillingnessPercentage:
          (optionBuybackList[0]?.offerFilter?.minimumWillingnessPercentage ??
            0) * 100,
      },
      recurringSetting: optionBuybackList[0]?.recurringSetting,
      updatedAt: optionBuybackList[0]?.updatedAt,
      participantListMethod:
        optionBuybackList[0]?.participantListMethod || "generate",
      buybackfile: optionBuybackList[0]?.buybackfile,
    };
  }

  const validationSchema = Yup.object().shape({
    eventName: Yup.string()
      .required("Name required")
      .min(3, "Name should be atleast 3 characters"),
    askWillingness: Yup.boolean().required(),
    buyerName: Yup.string().nullable(),
    companyId: Yup.string(),
    createdAt: Yup.string(),
    eventState: Yup.mixed().oneOf(Object.values(MonetaryEventState)),
    eventType: Yup.mixed().oneOf(Object.values(EventType)),
    id: Yup.string(),
    isDeleted: Yup.boolean(),
    isOnAutoPilot: Yup.boolean().required(),
    liquidityState: Yup.mixed().oneOf(Object.values(LiquidityState)),
    monetaryOffers: Yup.array(),
    startDate: Yup.string().required(),
    transactionType: Yup.mixed().oneOf(Object.values(MonetaryTransactionType)),
    updatedAt: Yup.string(),
    endDate: Yup.string().required("Buy Back offer end date required"),
    eventPercentage: Yup.number().when("participantListMethod", {
      is: "generate",
      then: Yup.number()
        .required("Percentage required")
        .max(100, "Percentage should be less than 100")
        .positive(),
    }),
    description: Yup.string()
      .required("Event description is required")
      .min(10, "Minimum 10 charcters required"),
    vestingCutOffDate: Yup.string().required(
      "Effective Buy Back date required"
    ),
    monetaryDetails: Yup.object().shape({
      price: Yup.number()
        .required("Buyback price required")
        .positive()
        .moreThan(0, "Buyback price must be greater than 0"),

      fmvPrice: Yup.number(),
      miscCharges: Yup.number(),
      sellingPrice: Yup.number(),
      paymentLink: Yup.string(),
      includeStrikePrice: Yup.boolean(),
    }),
    recurringSetting: Yup.object().shape({
      isRecurring: Yup.boolean(),
      recurringInterval: Yup.number().when(["isRecurring"], {
        is: true,
        then: Yup.number()
          .positive("Recurring Interval must be a positive number")
          .integer("Recurring interval must be a integer")
          .required(
            "Recurring Interval Required when creating recurring event"
          ),
      }),
      recurringIntervalUnit: Yup.string().when(["recurring"], {
        is: (recurring: boolean) => recurring,
        then: Yup.string().required(
          "Recurring Interval unit Required when creating recurring event"
        ),
      }),
      buybackfile: Yup.string().when("participantListMethod", {
        is: "upload",
        then: Yup.string().required("Please upload a file"),
      }),
    }),
    offerFilter: Yup.object().shape({
      minimumTenure: Yup.number().integer("Only accept integer value"),
      minimumVestedOptions: Yup.number().integer("Only accept integer value"),
      minimumWillingness: Yup.number().integer("Only accepts integer value"),
      department: Yup.array(),
      minimumWillingnessPercentage: Yup.number().max(
        100,
        "Percentage should be less than 100"
      ),
      participationConstraints: Yup.array(),
    }),
  });
  function getInitialValues() {
    if (searchParam === "edit") {
      return editInitialValues();
    } else {
      return createInitialValues();
    }
  }

  const { mutate: createOptionBuyback, isLoading } = useCreateOptionBuyback();
  const { mutate: editLiquidityEvent } = useEditLiquidityEvent();
  const [offers, setOffers] = useState();

  const { mutate: uploadOffers } = useUploadEmployeeOffers();
  const [uploadMessage, setUploadMessage] = useState<string>();
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    const excelFile = { file: base64 };
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      uploadOffers(excelFile, {
        onSuccess: (data) => {
          setOffers(data);
          setUploadMessage("Offer List uploaded successfully");
        },
        onError: () => {},
      });
      // eslint-disable-next-line no-useless-return
      return;
    }
  }
  useEffect(() => {
    if (
      (optionBuybackList[0]?.offerFilter.department || [])?.length > 0 &&
      searchParam === "edit"
    ) {
      setShowAdvanceFilters(true);
    }
  }, [optionBuybackList]);
  const { isMobile } = useIsMobile();
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);

  function clearForm(
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    formik: FormikProps<OptionBuyBackDetails>
  ) {
    formik.setFieldValue("buybackfile", "");
    e.currentTarget.value = "";
  }
  async function handleChange(
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<OptionBuyBackDetails>
  ) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const base64: string = (await convertToBase64(file)) as string;
      formik.setFieldValue("buybackfile", base64);
      setBuybackFileName(e.target.value);
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }
  function handleDeleteFile(formik: FormikProps<OptionBuyBackDetails>) {
    formik.setFieldValue("buybackfile", "");
    setBuybackFileName("");
    setErrorMessage(null); // Clear the error message
  }

  function handleSubmit(vlaues: OptionBuyBackDetails) {
    let message = "Option Buy-Back successfully edited";

    const buybackOptionsDto = vlaues;
    buybackOptionsDto.startDate = formatWithTimeZone(
      buybackOptionsDto.startDate
    );
    if (!buybackOptionsDto.recurringSetting.isRecurring) {
      buybackOptionsDto.recurringSetting.recurringInterval = 0;
      buybackOptionsDto.recurringSetting.recurringIntervalUnit =
        RecurringIntervalUnit.MONTH;
      buybackOptionsDto.isOnAutoPilot = false;
    }
    buybackOptionsDto.endDate = formatWithTimeZone(buybackOptionsDto.endDate);
    buybackOptionsDto.vestingCutOffDate = formatWithTimeZone(
      buybackOptionsDto.vestingCutOffDate
    );
    buybackOptionsDto.eventPercentage /= 100;
    buybackOptionsDto.offerFilter.minimumWillingnessPercentage /= 100;
    buybackOptionsDto.eventState = MonetaryEventState.DRAFT;
    buybackOptionsDto.liquidityState = LiquidityState.DRAFT;
    buybackOptionsDto.eventType = EventType.CASH;
    if (searchParam === "create") {
      message = "Option buy-back successfully created";
      delete buybackOptionsDto.id;
    }
    if (
      buybackOptionsDto.eventPercentage === 0 &&
      (searchParam === "create" || searchParam === "edit")
    ) {
      buybackOptionsDto.monetaryOffers = offers || [];
    }
    if (searchParam === "edit") {
      editLiquidityEvent(buybackOptionsDto, {
        onSuccess: (resData) => {
          toast(message, {
            type: "success",
            autoClose: 2000,
          });
          navigate(`/options/view-option-buy-back/${resData.id}`);
        },
        onError: (err: any) => {
          let errorMsg =
            err?.response?.data?.errorRaw || "Something went wrong";
          if (errorMsg.includes("Employee")) {
            const employeeErrors = errorMsg.split("Employee");
            errorMsg = employeeErrors.join("\nEmployee").trim();
          }

          setErrorMessage(errorMsg);
          toast(errorMsg, { type: "error", autoClose: 5000 });
        },
      });
      return;
    }
    createOptionBuyback(buybackOptionsDto, {
      onSuccess: (resData) => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/view-option-buy-back/${resData.id}`);
      },
      onError: (err: any) => {
        let errorMsg = err?.response?.data?.errorRaw || "Something went wrong";
        if (errorMsg.includes("Employee")) {
          const employeeErrors = errorMsg.split("Employee");
          errorMsg = employeeErrors.join("\nEmployee").trim();
        }

        setErrorMessage(errorMsg);
        toast(errorMsg, { type: "error", autoClose: 5000 });
      },
    });
  }

  return (
    <>
      {" "}
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formik) => (
          <>
            <Form>
              <Box
                className={`flex ${
                  isMobile
                    ? "flex-col"
                    : "flex-row justify-between gap-12  items-center "
                }  p-4 bg-white border-b border-solid rounded`}
              >
                <Box className="flex flex-row items-center">
                  <h6
                    className={`${
                      isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                    }  font-semibold text-gray-600`}
                  >
                    {searchParam === "create"
                      ? "Create option Buyback"
                      : "Edit option Buy Back"}
                  </h6>
                  <div className="items-center px-6 py-1 rounded">
                    <StatusLabel
                      state={
                        optionBuybackList[0]?.eventState ||
                        MonetaryEventState.DRAFT
                      }
                    />
                  </div>
                </Box>
                <HStack className="flex-row-reverse items-center justify-start gap-4">
                  {formik.values.recurringSetting.isRecurring && (
                    <div className="flex flex-row-reverse items-center justify-start gap-1">
                      <SwitchButton
                        value={formik.values?.isOnAutoPilot}
                        onClick={(e) => {
                          formik.setFieldValue(
                            "isOnAutoPilot",
                            !formik.values?.isOnAutoPilot
                          );
                        }}
                        label={"Auto Pilot"}
                      />
                      <Tooltip
                        text={`Auto Pilot mode 'ON' will not require manual actions for start/close of a liquidity event. `}
                        _className="w-[400px]"
                      >
                        <Icon
                          icon="material-symbols:info-outline-rounded"
                          height={24}
                          width={24}
                          className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                        />
                      </Tooltip>
                    </div>
                  )}
                  <SwitchButton
                    className="text-xs font-medium text-gray-dark"
                    value={formik.values.recurringSetting.isRecurring}
                    onClick={(e) => {
                      formik.setFieldValue(
                        "recurringSetting.isRecurring",
                        !formik.values.recurringSetting.isRecurring
                      );
                    }}
                    label={"Recurring Buy Back"}
                  />
                </HStack>
              </Box>
              <VStack className="gap-4 rounded">
                <VStack className="gap-8 p-4 bg-white rounded-md">
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } `}
                  >
                    <VStack className="w-full">
                      <Label>Buyback Name</Label>
                      <Input
                        type="text"
                        {...formik.getFieldProps("eventName")}
                      />
                      {formik.touched.eventName && formik.errors.eventName && (
                        <Error text={formik.errors.eventName} />
                      )}
                    </VStack>
                    <VStack className="w-full">
                      <Label>Buyback Price Per Option ({currencySymbol})</Label>
                      <Input
                        type="number"
                        {...formik.getFieldProps("monetaryDetails.price")}
                      />
                      {formik.touched.monetaryDetails?.price &&
                        formik.errors.monetaryDetails?.price && (
                          <Error text={formik.errors.monetaryDetails.price} />
                        )}
                    </VStack>
                  </Box>
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } `}
                  >
                    <VStack className="w-full">
                      <Label>Start Date</Label>
                      <Input
                        type="date"
                        {...formik.getFieldProps("startDate")}
                        min={format(new Date(), "yyyy-MM-dd")}
                      />
                      {formik.touched.startDate && formik.errors.startDate && (
                        <Error text={formik.errors.startDate} />
                      )}
                    </VStack>
                    <VStack className="w-full">
                      <Label>End Date</Label>
                      <Input
                        type="date"
                        {...formik.getFieldProps("endDate")}
                        min={formik.values.startDate}
                      />
                      {formik.touched.endDate && formik.errors.endDate && (
                        <Error text={formik.errors.endDate} />
                      )}
                    </VStack>
                  </Box>
                  <VStack>
                    <Box
                      className={`flex ${
                        formik.values.recurringSetting.isRecurring
                          ? "flex-col gap-4"
                          : "flex-row justify-between items-center gap-12"
                      }`}
                    >
                      <Box
                        className={`w-full flex ${
                          isMobile
                            ? " flex-col gap-4"
                            : "flex-row justify-between items-center gap-12"
                        }`}
                      >
                        <VStack className="w-full">
                          <Label>Description</Label>
                          <Input
                            type="text"
                            {...formik.getFieldProps("description")}
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <Error text={formik.errors.description} />
                            )}
                        </VStack>
                        {formik.values.recurringSetting.isRecurring && (
                          <VStack className="w-full">
                            <Label>Recurring interval</Label>
                            <HStack
                              className={` items-center rounded bg-slate-light text-slate-dark`}
                            >
                              <Input
                                type="number"
                                placeholder="Enter Interval"
                                {...formik.getFieldProps(
                                  "recurringSetting.recurringInterval"
                                )}
                              />
                              <Box className="border-r-2 border-solid border-orange-501">
                                <p className="text-slate-light">|</p>
                              </Box>
                              <Select
                                options={[
                                  RecurringIntervalUnit.QUARTER,
                                  RecurringIntervalUnit.MONTH,
                                  RecurringIntervalUnit.YEAR,
                                ]}
                                className="flex flex-1 w-96"
                                {...formik.getFieldProps(
                                  "recurringSetting.recurringIntervalUnit"
                                )}
                              />
                            </HStack>
                            <HStack className="justify-between">
                              {formik.touched.recurringSetting
                                ?.recurringInterval &&
                                formik.errors.recurringSetting
                                  ?.recurringInterval && (
                                  <Error
                                    text={
                                      formik.errors.recurringSetting
                                        ?.recurringInterval
                                    }
                                  />
                                )}
                              {formik.touched.recurringSetting
                                ?.recurringIntervalUnit &&
                                formik.errors.recurringSetting
                                  ?.recurringIntervalUnit && (
                                  <Error
                                    text={
                                      formik.errors.recurringSetting
                                        ?.recurringIntervalUnit
                                    }
                                  />
                                )}
                            </HStack>
                          </VStack>
                        )}
                      </Box>
                      <VStack
                        className={`${
                          formik.values.recurringSetting.isRecurring
                            ? "flex-1 flex-wrap"
                            : ""
                        } w-full`}
                      >
                        <Label>Payment Link</Label>
                        <Input
                          type="text"
                          {...formik.getFieldProps(
                            "monetaryDetails.paymentLink"
                          )}
                        />
                        {formik.touched.monetaryDetails?.paymentLink &&
                          formik.errors.monetaryDetails?.paymentLink && (
                            <Error
                              text={formik.errors.monetaryDetails?.paymentLink}
                            />
                          )}
                      </VStack>
                    </Box>
                  </VStack>
                </VStack>
                <VStack className="bg-white rounded-md">
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } border-b border-solid p-4 font-semibold text-xs text-gray-600`}
                  >
                    <h6
                      className={`${
                        isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                      }  font-semibold text-gray-600"`}
                    >
                      Choose a method by which the participant list is created
                    </h6>
                  </Box>
                  <VStack className="gap-8 p-4 ">
                    <HStack className="gap-2">
                      <HStack className="gap-56 p-4 ">
                        <VStack className=" gap-2 px-6 py-4 border-b rounded-md shadow-md">
                          <label className="flex items-center gap-2 cursor-pointer">
                            <input
                              type="radio"
                              name="participantListMethod"
                              value="generate"
                              checked={
                                formik.values.participantListMethod ===
                                "generate"
                              }
                              onChange={formik.handleChange}
                              className="hidden"
                            />
                            <div
                              className={`w-4 h-4 rounded-full border ${
                                formik.values.participantListMethod ===
                                "generate"
                                  ? "border-orange-501"
                                  : "border-gray-300"
                              } flex items-center justify-center`}
                            >
                              {formik.values.participantListMethod ===
                                "generate" && (
                                <div className="w-2 h-2 rounded-full bg-orange-501" />
                              )}
                            </div>
                            <span className="text-gray-600 text-lg font-medium">
                              Generate Employee List
                            </span>
                          </label>
                          <span className="text-gray-500 text-xxs pl-7">
                            This option is to auto generate the Offer list based
                            on the filters.
                          </span>
                        </VStack>
                        <VStack className=" gap-2 px-6 py-4 border-b rounded-md shadow-md">
                          <label className="flex items-center gap-2 cursor-pointer">
                            <input
                              type="radio"
                              name="participantListMethod"
                              value="upload"
                              checked={
                                formik.values.participantListMethod === "upload"
                              }
                              onChange={formik.handleChange}
                              className="hidden"
                            />
                            <div
                              className={`w-4 h-4 rounded-full border ${
                                formik.values.participantListMethod === "upload"
                                  ? "border-orange-501"
                                  : "border-gray-300"
                              } flex items-center justify-center`}
                            >
                              {formik.values.participantListMethod ===
                                "upload" && (
                                <div className="w-2 h-2 rounded-full bg-orange-501" />
                              )}
                            </div>
                            <span className="text-gray-600 text-lg font-medium">
                              Upload Employee List
                            </span>
                          </label>
                          <p className="text-gray-500 text-xxs pl-6">
                            This option is to Import customised offer list by
                            uploading an Excel file.
                          </p>
                        </VStack>
                      </HStack>
                    </HStack>
                  </VStack>
                </VStack>
                {formik.values.participantListMethod === "upload" && (
                  <VStack className="bg-white rounded-md">
                    <Box
                      className={` flex ${
                        isMobile
                          ? " flex-col gap-4"
                          : "flex-row  justify-between gap-12"
                      } border-b border-solid p-4 font-semibold text-xs text-gray-600`}
                    >
                      <h6
                        className={`${
                          isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                        }  font-semibold text-gray-600"`}
                      >
                        Transaction Settings{" "}
                      </h6>
                    </Box>
                    <VStack className="gap-8 p-4 ">
                      <HStack className="justify-start gap-2">
                        <HStack className="flex-row-reverse gap-4 ml-6">
                          <SwitchButton
                            className="text-xs font-medium text-gray-dark"
                            value={
                              formik.values.monetaryDetails.includeStrikePrice
                            }
                            onClick={(e) => {
                              formik.setFieldValue(
                                "monetaryDetails.includeStrikePrice",
                                !formik.values.monetaryDetails
                                  .includeStrikePrice
                              );
                            }}
                            label={"Deduct Strike Price "}
                          />
                        </HStack>
                        <Tooltip
                          text={`Enabling this option will deduct the strike price from the employee's Cashout/BuyBack amount.`}
                          _className="w-[400px]"
                        >
                          <Icon
                            icon="material-symbols:info-outline-rounded"
                            height={24}
                            width={24}
                            className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                          />
                        </Tooltip>
                      </HStack>
                    </VStack>
                  </VStack>
                )}
                {formik.values.participantListMethod === "generate" && (
                  <VStack className="bg-white rounded-md">
                    <Box
                      className={` flex ${
                        isMobile
                          ? " flex-col gap-4"
                          : "flex-row  justify-between gap-12"
                      } border-b border-solid p-4 font-semibold text-xs text-gray-600`}
                    >
                      <h6
                        className={`${
                          isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                        }  font-semibold text-gray-600"`}
                      >
                        Transaction Settings{" "}
                      </h6>
                    </Box>
                    <VStack className="gap-8 p-4 ">
                      <HStack className="justify-start gap-2">
                        <VStack className="w-1/2">
                          <HStack className="justify-start gap-2">
                            <HStack className="flex-row-reverse items-center gap-4">
                              <SwitchButton
                                value={formik.values.askWillingness}
                                className="font-medium text-gray-dark"
                                label={"Ask Willingness"}
                                onClick={() => {
                                  formik.setFieldValue(
                                    "askWillingness",
                                    !formik.values.askWillingness
                                  );
                                  formik.setFieldValue(
                                    "offerFilter.minimumWillingness",
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "offerFilter.minimumWillingnessPercentage",
                                    ""
                                  );
                                }}
                              />
                            </HStack>
                            <Tooltip
                              text={`Enabling the Ask willingness  will set the minimum willingness options for employees`}
                              _className="w-96 left-4"
                            >
                              <Icon
                                icon="material-symbols:info-outline-rounded"
                                height={24}
                                width={24}
                                className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                              />
                            </Tooltip>
                          </HStack>
                        </VStack>

                        <HStack className="flex-row-reverse gap-4 ml-6">
                          <SwitchButton
                            className="text-xs font-medium text-gray-dark"
                            value={
                              formik.values.monetaryDetails.includeStrikePrice
                            }
                            onClick={(e) => {
                              formik.setFieldValue(
                                "monetaryDetails.includeStrikePrice",
                                !formik.values.monetaryDetails
                                  .includeStrikePrice
                              );
                            }}
                            label={"Deduct Strike Price "}
                          />
                        </HStack>
                        <Tooltip
                          text={`Enabling this option will deduct the strike price from the employee's Cashout/BuyBack amount.`}
                          _className="w-[400px]"
                        >
                          <Icon
                            icon="material-symbols:info-outline-rounded"
                            height={24}
                            width={24}
                            className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                          />
                        </Tooltip>
                      </HStack>

                      {formik.values.askWillingness && (
                        <Box
                          className={` flex ${
                            isMobile
                              ? " flex-col gap-4 mt-4"
                              : "flex-col  justify-start gap-12 mt-4"
                          } `}
                        >
                          <HStack className="gap-12">
                            <VStack className="w-1/2">
                              <Label>
                                Minimum Willingness for all Offers (Optional)
                              </Label>
                              <div className="relative tooltip-container">
                                <Input
                                  type="number"
                                  {...formik.getFieldProps(
                                    "offerFilter.minimumWillingness"
                                  )}
                                  disabled={
                                    !!formik.values.offerFilter
                                      .minimumWillingnessPercentage
                                  }
                                  className={`${
                                    formik.values.offerFilter
                                      .minimumWillingnessPercentage
                                      ? "cursor-not-allowed opacity-60"
                                      : ""
                                  }`}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value) {
                                      formik.setFieldValue(
                                        "offerFilter.minimumWillingnessPercentage",
                                        ""
                                      );
                                    }
                                    formik.setFieldValue(
                                      "offerFilter.minimumWillingness",
                                      value
                                    );
                                  }}
                                />
                                {Boolean(
                                  formik.values.offerFilter
                                    .minimumWillingnessPercentage
                                ) && (
                                  <div className="custom-tooltip text-gray-201">
                                    This field is disabled because Percentage of
                                    Eligible options is already set
                                  </div>
                                )}
                              </div>
                              {formik.touched.offerFilter?.minimumWillingness &&
                                formik.errors.offerFilter
                                  ?.minimumWillingness && (
                                  <Error
                                    text={
                                      formik.errors.offerFilter
                                        ?.minimumWillingness
                                    }
                                  />
                                )}
                            </VStack>

                            <VStack className="w-1/2">
                              <Label>
                                Percentage of Eligible options for all offer
                                (Optional)
                              </Label>
                              <div className="relative tooltip-container">
                                <Input
                                  type="number"
                                  {...formik.getFieldProps(
                                    "offerFilter.minimumWillingnessPercentage"
                                  )}
                                  disabled={
                                    !!formik.values.offerFilter
                                      .minimumWillingness
                                  }
                                  className={`${
                                    formik.values.offerFilter.minimumWillingness
                                      ? "cursor-not-allowed opacity-60"
                                      : ""
                                  }`}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value) {
                                      formik.setFieldValue(
                                        "offerFilter.minimumWillingness",
                                        ""
                                      );
                                    }
                                    formik.setFieldValue(
                                      "offerFilter.minimumWillingnessPercentage",
                                      value
                                    );
                                  }}
                                />
                                {Boolean(
                                  formik.values.offerFilter.minimumWillingness
                                ) && (
                                  <div className="custom-tooltip text-gray-201">
                                    This field is disabled because Minimum
                                    Willingness is already set
                                  </div>
                                )}
                              </div>
                              {formik.touched.offerFilter
                                ?.minimumWillingnessPercentage &&
                                formik.errors.offerFilter
                                  ?.minimumWillingnessPercentage && (
                                  <Error
                                    text={
                                      formik.errors.offerFilter
                                        ?.minimumWillingnessPercentage
                                    }
                                  />
                                )}
                            </VStack>
                          </HStack>
                        </Box>
                      )}
                    </VStack>
                  </VStack>
                )}
                {formik.values.participantListMethod === "generate" && (
                  <VStack className="bg-white rounded-md ">
                    <Box
                      className={` flex ${
                        isMobile
                          ? " flex-col gap-4"
                          : "flex-row  justify-between gap-12"
                      } border-b border-solid p-4 font-semibold text-xs text-gray-600`}
                    >
                      <h6
                        className={`${
                          isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                        }  font-semibold text-gray-600"`}
                      >
                        Employee Related filters{" "}
                      </h6>
                    </Box>
                    <VStack className="gap-8 p-4 ">
                      <Box
                        className={` flex ${
                          isMobile
                            ? " flex-col gap-4"
                            : "flex-row  justify-between gap-12"
                        } `}
                      >
                        <VStack className="w-full">
                          <Label>Percentage of vested options</Label>
                          <Input
                            type="number"
                            {...formik.getFieldProps("eventPercentage")}
                          />
                          {formik.touched.eventPercentage &&
                            formik.errors.eventPercentage && (
                              <Error text={formik.errors.eventPercentage} />
                            )}
                        </VStack>
                        <VStack className="w-full">
                          <Label>Vesting Cut Off Date </Label>
                          <Input
                            type="date"
                            max={formik.values.startDate}
                            {...formik.getFieldProps("vestingCutOffDate")}
                          />
                          {formik.touched.vestingCutOffDate &&
                            formik.errors.vestingCutOffDate && (
                              <Error text={formik.errors.vestingCutOffDate} />
                            )}
                        </VStack>
                      </Box>
                      <Box
                        className={`flex ${
                          isMobile
                            ? "flex-col gap-4"
                            : "flex-row justify-between gap-12"
                        }`}
                      ></Box>
                      <HStack className="items-center gap-2 before:border-b-2 before:border-dashed before:m-auto after:flex-1 after:border-dashed after:content-[''] after:border-b-2">
                        <ButtonPrimary1
                          onClick={() => {
                            setShowAdvanceFilters(!showAdvanceFilters);
                          }}
                          className="flex flex-row bg-transparent border border-solid cursor-pointer border-orange-501 hover:bg-transparent"
                        >
                          Advance Filters
                          {showAdvanceFilters ? (
                            <Icon
                              icon="material-symbols:keyboard-arrow-down-rounded "
                              className=""
                            />
                          ) : (
                            <Icon
                              icon="material-symbols:keyboard-arrow-down-rounded "
                              className=""
                            />
                          )}
                        </ButtonPrimary1>
                      </HStack>
                      {showAdvanceFilters && (
                        <VStack className="gap-6">
                          <Box
                            className={` flex ${
                              isMobile
                                ? " flex-col gap-4"
                                : "flex-row  justify-between gap-12"
                            } `}
                          >
                            <VStack className="w-full">
                              <Label>Department (Optional)</Label>
                              <MultiSelect
                                _className="w-full"
                                multiple={true}
                                placeholder={"--Select departments--"}
                                options={deprt || []}
                                handleSelection={(e) => {
                                  formik.setFieldValue(
                                    "offerFilter.department",
                                    e
                                  );
                                }}
                                optionValues={
                                  formik.values.offerFilter.department || []
                                }
                              />
                              {formik.touched.offerFilter?.department &&
                                formik.errors.offerFilter?.department && (
                                  <Error
                                    text={formik.errors.offerFilter.department.toString()}
                                  />
                                )}
                            </VStack>
                            <VStack className="w-full">
                              <Label>
                                Minimum Employment Tenure (Year) (Optional)
                              </Label>
                              <Input
                                type="number"
                                {...formik.getFieldProps(
                                  "offerFilter.minimumTenure"
                                )}
                              />
                              {formik.touched.offerFilter?.minimumTenure &&
                                formik.errors.offerFilter?.minimumTenure && (
                                  <Error
                                    text={
                                      formik.errors.offerFilter?.minimumTenure
                                    }
                                  />
                                )}
                            </VStack>
                          </Box>

                          <Box
                            className={` flex ${
                              isMobile
                                ? " flex-col gap-4"
                                : "flex-row  justify-start gap-12"
                            } `}
                          >
                            <VStack className="w-full">
                              <Label>Minimum Eligible Options (Optional)</Label>
                              <Input
                                type="number"
                                {...formik.getFieldProps(
                                  "offerFilter.minimumVestedOptions"
                                )}
                              />
                              {formik.touched.offerFilter
                                ?.minimumVestedOptions &&
                                formik.errors.offerFilter
                                  ?.minimumVestedOptions && (
                                  <Error
                                    text={
                                      formik.errors.offerFilter
                                        ?.minimumVestedOptions
                                    }
                                  />
                                )}
                            </VStack>

                            <VStack className="w-full"></VStack>
                          </Box>
                          <EventParticipationConstraints />
                        </VStack>
                      )}
                      {!isUserEsopViewer && (
                        <HStack
                          className={`${
                            isMobile ? "justify-center" : "flex-row-reverse"
                          }`}
                        >
                          <ButtonPrimary
                            type="submit"
                            className="items-center px-8 "
                            loading={isLoading}
                          >
                            {searchParam === "create"
                              ? "Generate Offer List"
                              : "Save"}
                          </ButtonPrimary>
                        </HStack>
                      )}
                    </VStack>
                  </VStack>
                )}
                <VStack>
                  {formik.values.participantListMethod === "upload" && (
                    <Box className="p-4 bg-white rounded-md border border-solid border-gray-200">
                      <Box className="flex flex-col gap-2 border-b border-solid pb-4">
                        <h6
                          className={`${
                            isMobile ? "text-xs" : "text-lg"
                          } font-semibold text-gray-600`}
                        >
                          Participant List Information
                        </h6>
                      </Box>

                      <VStack className="gap-4 p-4">
                        <Label>
                          Upload the list of participating employees here
                        </Label>

                        <Box className="flex items-center gap-4">
                          <HStack className="gap-2 items-center">
                            <Box className="flex-1">
                              <FileInput
                                accept=".xlsx, .xls, .ods"
                                onClick={(e) => clearForm(e, formik)}
                                onChange={(e) => handleChange(e, formik)}
                                multiple={false}
                                type="file"
                                file={buybackFileName || "Choose File"}
                              />
                            </Box>
                            {buybackFileName && (
                              <Icon
                                width={20}
                                icon="mdi:delete"
                                className="text-orange-500 cursor-pointer"
                                onClick={() => handleDeleteFile(formik)}
                              />
                            )}
                          </HStack>
                        </Box>
                        {formik.touched.buybackfile &&
                          formik.errors.buybackfile && (
                            <Error text={formik.errors.buybackfile} />
                          )}
                        {errorMessage && (
                          <div className="text-red-500 text-xs mt-2">
                            {errorMessage.split("\n").map((line, index) => (
                              <p key={index} className="my-1">
                                {line.startsWith("Employee") ? "• " : ""}
                                {line.startsWith("Invalid") ? "• " : ""}
                                {line.startsWith("Empty") ? "• " : ""}
                                {line.startsWith("The") ? "• " : ""}
                                {line.startsWith("Duplicate") ? "• " : ""}
                                {line.startsWith("Missing") ? "• " : ""}
                                {line}
                              </p>
                            ))}
                          </div>
                        )}

                        <HStack className="items-center gap-2">
                          <span className="text-gray-500">or</span>
                          <span className="text-gray-600">
                            Download template from
                          </span>
                          <a
                            href="#"
                            className="text-blue-500 underline"
                            onClick={(e) => {
                              e.preventDefault();
                              downloadS3File(
                                "https://equion-dev.s3.us-west-2.amazonaws.com/Upload_template/offerUploadTemplate.xlsx",
                                "OfferUploadTemplate.xlsx"
                              );
                              // Add download template logic here
                            }}
                          >
                            here
                          </a>
                          <span className="text-gray-600">if required</span>
                        </HStack>

                        {uploadMessage && (
                          <Box className="text-sm text-green-500">
                            {uploadMessage}
                          </Box>
                        )}

                        <HStack
                          className={`${
                            isMobile ? "justify-center" : "flex-row-reverse"
                          } mt-4`}
                        >
                          {!isUserEsopViewer && (
                            <HStack
                              className={`${
                                isMobile ? "justify-center" : "flex-row-reverse"
                              }`}
                            >
                              <ButtonPrimary
                                type="submit"
                                className="items-center px-8 "
                                loading={isLoading}
                              >
                                {searchParam === "create"
                                  ? "Generate Offer List"
                                  : "Save"}
                              </ButtonPrimary>
                            </HStack>
                          )}
                        </HStack>
                      </VStack>
                    </Box>
                  )}
                </VStack>
              </VStack>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

export default CreateBuybackDetails;

export function EventParticipationConstraints() {
  const formik = useFormikContext<OptionBuyBackDetails>();
  const { isMobile } = useIsMobile();
  const { values, errors, touched, setFieldValue } = formik;
  function handleAdd() {
    const constraints = [
      ...(values.offerFilter.participationConstraints || []),
    ];
    const newConstraints = baseParticipationConstraints;
    constraints.push(newConstraints);

    setFieldValue("offerFilter.participationConstraints", constraints);
  }

  return (
    <>
      {values?.offerFilter.participationConstraints &&
        values?.offerFilter.participationConstraints?.map((_, index) => {
          const constraints =
            values?.offerFilter?.participationConstraints[index];
          const fieldTouched =
            touched?.offerFilter?.participationConstraints?.[index];
          const err = errors?.offerFilter?.participationConstraints?.[
            index
          ] as any;
          return (
            <Box className={`flex flex-col`} key={index}>
              <Box
                className={`flex ${
                  isMobile
                    ? " flex-col gap-4"
                    : "flex-row justify-start gap-12 items-center"
                } `}
              >
                <Box
                  className={`${
                    isMobile ? "flex-col" : "flex-row items-center gap-4"
                  } flex  justify-start `}
                >
                  <Label className="whitespace-nowrap">
                    Maximum Participation (Optional)
                  </Label>
                  <Input
                    type="number"
                    placeholder="Enter Participation Number"
                    value={constraints.maxFrequency}
                    onChange={(e) =>
                      setFieldValue(
                        `offerFilter.participationConstraints[${index}].maxFrequency`,
                        e.target.value
                      )
                    }
                  />
                  {fieldTouched?.maxFrequency && err?.maxFrequency && (
                    <Error text={err?.maxFrequency} />
                  )}
                </Box>
                <Box
                  className={`${
                    isMobile ? "flex-col" : "flex-row items-center gap-4"
                  } flex  justify-start `}
                >
                  <Label className="whitespace-nowrap">
                    No of times (Optional){" "}
                  </Label>
                  <HStack
                    className={` items-center rounded bg-slate-light text-slate-dark`}
                  >
                    <Input
                      type="number"
                      placeholder="Enter Interval"
                      value={constraints.interval}
                      onChange={(e) =>
                        setFieldValue(
                          `offerFilter.participationConstraints[${index}].interval`,
                          e.target.value
                        )
                      }
                    />
                    <Box className="border-r-2 border-solid border-orange-501">
                      <p className="text-slate-light">|</p>
                    </Box>
                    <Select
                      options={["MONTH", "QUARTER", "YEAR"]}
                      className=""
                      value={constraints.intervalUnit}
                      onChange={(e) =>
                        setFieldValue(
                          `offerFilter.participationConstraints[${index}].intervalUnit`,
                          e.target.value
                        )
                      }
                    />
                  </HStack>
                  {fieldTouched?.intervalUnit && err?.intervalUnit && (
                    <Error text={err?.intervalUnit} />
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      <ButtonPrimary1
        onClick={() => {
          handleAdd();
        }}
        className="max-w-min"
      >
        +Add
      </ButtonPrimary1>
    </>
  );
}
