/* eslint-disable no-undef */
import React, { useEffect, useRef } from "react";
import { BuybackType } from "../../pages/optionBuyback";
import { Box, ButtonPrimary, isEsopViewer } from "../utils";
import { Action } from "./Dropdown";
import Tooltip from "./Tooltip";

function DropdownButton({
  actions,
  onAction,
  className = "items-end",
  render = () => (
    <Box className="flex items-center gap-1">
      <div>+</div>
      <div>Quick Actions</div>
    </Box>
  ),
  subtitle = "Quick Actions",
}: {
  className?: string;
  actions: Action[] | BuybackType[];
  onAction: (action: any) => void;
  render?: () => JSX.Element;
  subtitle?: string;
}) {
  const isUserEsopViewer = isEsopViewer();
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);
  const RenderElement = render;
  return (
    <div className={`flex flex-col ${className} `} ref={contextRef}>
      <ButtonPrimary
        disabled={isUserEsopViewer}
        onClick={() => {
          setDropDownOpen((state) => !state);
        }}
        className={`px-5 py-2 font-normal text-white rounded cursor-pointer peer h-8  bg-orange-p focus:opacity-90 hover:bg-orange-500 ${
          isUserEsopViewer ? "cursor-not-allowed" : ""
        }`}
        type="button"
      >
        <RenderElement />
      </ButtonPrimary>
      {dropdownOpen && (
        <div
          id="dropdown"
          className="absolute z-10 bg-white border divide-y divide-gray-100 rounded shadow mt-11"
        >
          <p className="px-4 py-1 text-stone-300">{subtitle}</p>
          <ul
            className="w-48 py-1 text-xs font-normal divide-y text-dark"
            aria-labelledby="dropdownDefault"
          >
            {actions.map((action, index) =>
              action.disabled ? (
                <Tooltip
                  text={`${
                    action.disabled
                      ? action.type === "Permission"
                        ? action.tooltipText
                        : "To create a new Event, Please complete your previous closed events"
                      : ""
                  }`}
                  key={index}
                >
                  <li>
                    <a
                      href=""
                      className={` block px-4 py-2 hover:bg-gray-100  ${
                        action.disabled && "text-gray-300 cursor-not-allowed "
                      } `}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={(e) => {
                        e.preventDefault();
                        onAction(action);
                        setDropDownOpen((state) => !state);
                      }}
                    >
                      {action.name}
                    </a>
                  </li>
                </Tooltip>
              ) : (
                <li key={index}>
                  <a
                    href=""
                    className={` block px-4 py-2 hover:bg-gray-100  ${
                      action.disabled && "text-gray-300 cursor-not-allowed "
                    } `}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      onAction(action);
                      setDropDownOpen((state) => !state);
                    }}
                  >
                    {action.name}
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropdownButton;
