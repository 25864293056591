import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import React, { useState } from "react";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { Switch } from "@mui/material";
import { Input, Label, TextArea } from "../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  Error,
  getCompanyName,
  HStack,
  VStack,
} from "../components/utils";
import { CorporateActionDetails } from "../types/CompanyProfile";
import {
  useApproveBonus,
  useGenerateBonusReport,
  useUploadDocumentTemplate,
} from "../queries/onBoarding";
import { getCurrencySymbol } from "../utils/currencyFormatter";
import { formatDate } from "../utils/date";
import { BonusSummaryReport } from "../pages/companyProfile/CorporateActionDetailsCard";
import Tooltip from "../components/shared/Tooltip";
import { downloadS3File } from "../utils/DownloadFile";
import { useCompanyStore } from "../store";

export function AddOrEditBonusDetails({
  corporateAction,
  mode,
  onClose,
}: {
  corporateAction?: CorporateActionDetails;
  mode: "AddBonus" | "EditBonus";
  onClose: () => void;
}) {
  const initialValues: CorporateActionDetails = {
    id: corporateAction?.id,
    name: corporateAction?.name || "",
    date: corporateAction?.date ? formatDate(corporateAction?.date || "") : "",
    ratio: corporateAction?.ratio || 1,
    bonusStrikePrice: corporateAction?.bonusStrikePrice,
    type: corporateAction?.type || "BONUS",
    roundingMethodology: "",
    state: corporateAction?.state || "DRAFT",
    settings: corporateAction?.settings,
    bonusTemplates: corporateAction?.bonusTemplates,
  };
  const { mutate: generateBonusReport } = useGenerateBonusReport();

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Bonus Name"),
    date: Yup.date().required("Bonus Date"),
    ratio: Yup.number().positive().required("Bonus Ratio Required"),
    bonusStrikePrice: Yup.number()
      .positive()
      .required("Bonus Strike Price Required"),
  });

  const handleSubmit = (values: CorporateActionDetails) => {
    setIsSaveLoading(true);
    generateBonusReport(values, {
      onSuccess: (data) => {
        toast("Bonus Details Updated Successfully", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
        setIsSaveLoading(false);
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorRaw;
        toast(errMsg || "Error Creating Bonus Details", {
          type: "error",
          autoClose: 2000,
        });
        setIsSaveLoading(false);
      },
    });
  };

  const currencySymbol = getCurrencySymbol();

  const { mutate: approveBonus } = useApproveBonus();

  function handleApproveBonus(values: CorporateActionDetails) {
    if (JSON.stringify(initialValues) !== JSON.stringify(values)) return;

    setIsApproveLoading(true);
    approveBonus(corporateAction?.id || "", {
      onSuccess: (data) => {
        toast("Bonus Approved Successfully", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
        setIsApproveLoading(false);
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorRaw;
        toast(errMsg || "Error Creating Bonus Details", {
          type: "error",
          autoClose: 2000,
        });
        setIsApproveLoading(false);
      },
    });
  }

  const [isBonusGrantLetterLoading, setIsBonusGrantLetterLoading] =
    useState(false);

  const { mutate: uploadDocument } = useUploadDocumentTemplate();

  const { companyData } = useCompanyStore();

  async function handleBonusGrantLetterUpload(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files?.[0];
    if (file) {
      const companyName = companyData?.name || "";
      const document = {
        file,
        key: `/${companyName}/Corporate_Action_${
          corporateAction?.name
        }/Bonus-Grant-Letter_${new Date().toISOString()}.docx`,
        contentType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        templateName: "Bonus Grant Letter",
        companyId: companyData?.id || "",
        templateType: "BONUS_GRANT_LETTER",
        entityType: "CORPORATE_ACTION",
        entityId: corporateAction?.id || "",
      };
      uploadDocument(document, {
        onSuccess: () => {
          toast("Grant Letter Template uploaded Successfully", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          const errMsg = err.response?.data?.errorsValidation?.errorRaw;
          if (errMsg) {
            toast(errMsg, {
              type: "error",
              autoClose: 2000,
            });
          }
        },
      });
    }
  }

  return (
    <>
      <div className="">
        <div className="px-10 text-lg font-medium border-b py-7">
          <h6 className="flex justify-between">
            <p className="font-bold">Bonus </p>
            <span onClick={() => onClose()} className="cursor-pointer">
              X
            </span>{" "}
          </h6>
        </div>
        <VStack className="w-full px-10 py-5 gap-9">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form>
                <VStack className="gap-6">
                  <HStack className="justify-between gap-4">
                    <VStack className="w-1/2">
                      <HStack className="items-center">
                        <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                          Bonus Name
                        </Label>
                        <VStack className="gap-1 w-full">
                          <Input
                            type="text"
                            {...formik.getFieldProps("name")}
                          />
                          <div>
                            {formik.touched.name && formik.errors.name && (
                              <Error text={formik.errors.name} />
                            )}
                          </div>
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack className="w-1/2">
                      <HStack className="items-center">
                        <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                          Bonus Date
                        </Label>
                        <VStack className="gap-1 w-full">
                          <Input
                            type="date"
                            {...formik.getFieldProps("date")}
                          />
                          <div>
                            {formik.touched.date && formik.errors.date && (
                              <Error text={formik.errors.date} />
                            )}
                          </div>
                        </VStack>
                      </HStack>
                    </VStack>
                  </HStack>
                  <HStack className="space-between gap-4">
                    <VStack className="w-1/2">
                      <HStack className="items-center">
                        <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                          Bonus Ratio
                        </Label>
                        <VStack className="gap-1 w-full">
                          <HStack className="items-center">
                            <p className="text-sm font-normal">1:</p>
                            <Input
                              type="number"
                              {...formik.getFieldProps("ratio")}
                            />
                          </HStack>
                          {formik.touched.ratio && formik.errors.ratio && (
                            <Error text={formik.errors.ratio} />
                          )}
                        </VStack>
                      </HStack>
                      <HStack className="justify-end pt-1">
                        {formik.values.ratio > 0 && (
                          <p className="text-xs font-semi-medium text-gray-light">
                            {formik.values.ratio} bonus option(s) for every 1
                            outstanding option
                          </p>
                        )}
                      </HStack>
                    </VStack>
                    <VStack className="w-1/2">
                      <HStack className="items-center">
                        <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                          Bonus Strike Price ({currencySymbol})
                        </Label>
                        <VStack className="gap-1 w-full">
                          <Input
                            type="number"
                            {...formik.getFieldProps("bonusStrikePrice")}
                          />
                          <div>
                            {formik.touched.bonusStrikePrice &&
                              formik.errors.bonusStrikePrice && (
                                <Error text={formik.errors.bonusStrikePrice} />
                              )}
                          </div>
                        </VStack>
                      </HStack>
                    </VStack>
                  </HStack>
                  <HStack className="space-between gap-4 items-center">
                    <VStack className="w-1/2">
                      <HStack className="items-center">
                        <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                          Notes
                        </Label>
                        <VStack className="gap-1 w-full">
                          <TextArea {...formik.getFieldProps("note")} />
                          {formik.touched.ratio && formik.errors.ratio && (
                            <Error text={formik.errors.ratio} />
                          )}
                        </VStack>
                      </HStack>
                    </VStack>
                    <VStack className="w-1/2">
                      <HStack className="items-center justify-between">
                        <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                          Notify Email to Employees
                        </Label>
                        <Switch
                          checked={formik.values.settings?.notifyEmployees}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "settings.notifyEmployees",
                              !formik.values.settings?.notifyEmployees
                            )
                          }
                          value={formik.values.settings?.notifyEmployees}
                        />
                      </HStack>
                    </VStack>
                  </HStack>
                  {mode === "EditBonus" && (
                    <HStack className="space-between gap-4 items-center">
                      <VStack className="w-1/2">
                        <HStack className="items-center justify-between">
                          <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                            Generate Bonus Grant Letters
                          </Label>
                          <Switch
                            checked={
                              formik.values.settings?.generateBonusGrantLetter
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                "settings.generateBonusGrantLetter",
                                !formik.values.settings
                                  ?.generateBonusGrantLetter
                              );
                              if (
                                !formik.values.settings
                                  ?.generateBonusGrantLetter
                              )
                                formik.setFieldValue(
                                  "settings.useBonusGrantLetterTemplate",
                                  false
                                );
                            }}
                            value={
                              formik.values.settings?.generateBonusGrantLetter
                            }
                          />
                        </HStack>
                      </VStack>
                      <VStack className="w-1/2">
                        {formik.values.settings?.generateBonusGrantLetter && (
                          <VStack className="gap-2">
                            <HStack className="items-center justify-between">
                              <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                                Use Bonus Grant Letter Template
                              </Label>
                              <Switch
                                checked={
                                  formik.values.settings
                                    ?.useBonusGrantLetterTemplate
                                }
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "settings.useBonusGrantLetterTemplate",
                                    !formik.values.settings
                                      ?.useBonusGrantLetterTemplate
                                  )
                                }
                                value={
                                  formik.values.settings
                                    ?.useBonusGrantLetterTemplate
                                }
                              />
                            </HStack>
                            <HStack className="gap-2">
                              {formik.values.bonusTemplates
                                ?.bonusGrantLetterTemplateUrl && (
                                <ViewReport
                                  s3url={
                                    formik.values.bonusTemplates
                                      ?.bonusGrantLetterTemplateUrl
                                  }
                                  documentName="Grant Letter Template.docx"
                                />
                              )}
                              <ButtonPrimary1
                                className={``}
                                loading={isBonusGrantLetterLoading}
                              >
                                <label className="cursor-pointer">
                                  <Input
                                    type="file"
                                    className="hidden"
                                    accept="application/docx"
                                    onChange={(e) => {
                                      setIsBonusGrantLetterLoading(true);
                                      handleBonusGrantLetterUpload(e).finally(
                                        () =>
                                          setIsBonusGrantLetterLoading(false)
                                      );
                                    }}
                                  />
                                  Upload Grant Letter Template
                                </label>
                              </ButtonPrimary1>
                            </HStack>
                          </VStack>
                        )}
                      </VStack>
                    </HStack>
                  )}
                  <HStack className="space-between gap-4 pt-4">
                    <div className="w-1/2">
                      {mode === "EditBonus" && corporateAction?.s3url && (
                        <VStack className="w-full">
                          <div>
                            <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                              Bonus Summary Report
                            </Label>
                            <BonusSummaryReport
                              s3url={corporateAction?.s3url || ""}
                            />
                          </div>
                        </VStack>
                      )}
                    </div>
                  </HStack>
                  <HStack className="justify-between mt-4">
                    <ButtonSecondary
                      className="h-8 px-2 py-1"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </ButtonSecondary>
                    <HStack className="gap-5">
                      {corporateAction?.state === "DRAFT" &&
                        mode === "EditBonus" && (
                          <>
                            <ButtonPrimary1
                              type="submit"
                              loading={isSaveLoading}
                            >
                              Save
                            </ButtonPrimary1>
                            <Tooltip
                              text={
                                JSON.stringify(initialValues) !==
                                JSON.stringify(formik.values)
                                  ? "Please Save details to approve"
                                  : "Approve"
                              }
                            >
                              <ButtonPrimary
                                onClick={() => {
                                  //Confirm Approve
                                  handleApproveBonus(formik.values);
                                }}
                                loading={isApproveLoading}
                                disabled={
                                  JSON.stringify(initialValues) !==
                                  JSON.stringify(formik.values)
                                }
                              >
                                Approve
                              </ButtonPrimary>
                            </Tooltip>
                          </>
                        )}
                      {mode === "AddBonus" && (
                        <ButtonPrimary type="submit" loading={isSaveLoading}>
                          Save
                        </ButtonPrimary>
                      )}
                    </HStack>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </>
  );
}

function ViewReport({
  s3url,
  documentName,
}: {
  s3url: string;
  documentName: string;
}) {
  return (
    <Tooltip text={"Click to download Report"}>
      <div
        className="cursor-pointer"
        onClick={() => {
          downloadS3File(s3url || "", documentName);
        }}
      >
        <HStack className="items-center">
          <Icon icon="mdi:file-word" width={32} color="#1E90FF" />
          <p className="text-sm font-semi-medium hover:text-red-600 underline">
            {documentName}
          </p>
        </HStack>
      </div>
    </Tooltip>
  );
}
