import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-community";
import { differenceInSeconds } from "date-fns";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { Dialog } from "@mui/material";
import { useGetAllCompanyConfigs, useScheduleSync } from "../../queries";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  getCompanyName,
  HStack,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { formatDisplayDate } from "../../utils/date";
import { BulkActions } from "../esopOverview/GrantsTable";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { downloadSync } from "../../api/Esop";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";

function SyncDetails() {
  const { data: syncConfigs, refetch: refetchConfigs } =
    useGetAllCompanyConfigs();
  const gridApi = useRef<GridApi | any>(null);
  const { mutate: startSync } = useScheduleSync();
  const noDataImageUrl =
    "https://equion-dev.s3.us-west-2.amazonaws.com/assets/emptyTable.png";
  const navigate = useNavigate();
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };
  const [reportBeingDownloaded, setReportBeingDownloaded] = useState(false);
  const maxDate = new Date();
  const [downloadDialogData, setDownloadDialogData] = useState<{
    open: boolean;
    fromDate?: Date;
    toDate?: Date;
    minDate?: Date;
    datasource?: string;
  }>({
    open: false,
  });
  const companyName = getCompanyName();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass:
        "multiline text-xs font-medium text-gray-dark leading-5 text-left",
      menuTabs: ["filterMenuTab"],
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "DATA SOURCE",
        field: "masterDataType",
        valueFormatter: (e) => {
          switch (e.value) {
            case "DarwinboxDataset":
              return "Darwin Box";
            default:
              return e.value;
          }
        },
      },
      {
        headerName: "STATUS",
        field: "status",
      },
      {
        headerName: "SYNC TYPE",
        field: "syncType",
      },
      {
        headerName: "SYNC TIME",
        field: "syncTime",
      },
      {
        headerName: "NOTE",
        field: "note",
        wrapText: false,
        filter: false,
        suppressMenu: true,
        suppressSorting: true,
        sortable: false,
      },
    ],
    []
  );
  const earliestSync = useMemo(() => {
    const earliestDate = _.minBy(
      syncConfigs,
      (config) => new Date(config.createdAt)
    )?.createdAt;
    return earliestDate ? new Date(earliestDate) : new Date();
  }, [syncConfigs]);
  const rowData = useMemo(
    () =>
      syncConfigs?.flatMap((config) => {
        const dataToDisplay = config?.employeeDataSyncHistories?.map(
          (history) => ({
            masterDataType: config.masterDataType,
            status: history.syncStatus,
            syncType: history.syncType,
            syncTime: formatDisplayDate(new Date(history.createdAt)),
            syncDuration: differenceInSeconds(
              new Date(history.updatedAt),
              new Date(history.createdAt)
            ),
            note: history.note,
          })
        );
        return dataToDisplay;
      }) || [],
    [syncConfigs]
  );
  const actions = useMemo(
    () =>
      Array.from(
        new Set(
          syncConfigs?.map((config) =>
            config.masterDataType === "DarwinboxDataset"
              ? "Darwin Box"
              : config.masterDataType
          )
        )
      ),
    [syncConfigs]
  );
  function handleBulkAction(action: Action) {
    if (action.name === "Darwin Box") {
      toast("Darwin Box sync started", { autoClose: 2000, type: "info" });
      startSync("DarwinboxDataset", {
        onSuccess: () => {
          toast("Sync Successful", { autoClose: 2000, type: "success" });
        },
        onError: () => {
          toast("Error while Syncing", { autoClose: 2000, type: "error" });
          refetchConfigs();
        },
      });
      setTimeout(() => {
        refetchConfigs();
      }, 1000);
    } else if (action.name === "GREYTIP") {
      toast("GREYTIP sync started", { autoClose: 2000, type: "info" });
      startSync("GREYTIP", {
        onSuccess: () => {
          toast("Sync Successful", { autoClose: 2000, type: "success" });
        },
        onError: () => {
          toast("Error while Syncing", { autoClose: 2000, type: "error" });
          refetchConfigs();
        },
      });
      setTimeout(() => {
        refetchConfigs();
      }, 1000);
    }
  }
  const downloadSyncHistory = () => {
    setReportBeingDownloaded(true);
    downloadSync({
      datasource: downloadDialogData.datasource,
      fromDate: downloadDialogData.fromDate,
      toDate: downloadDialogData.toDate,
    })
      .then((data) => {
        convertBase64ToBlob(data?.data?.base64File, data?.data?.fileType).then(
          (blobObject) => {
            downloadBlobObject(
              blobObject,
              `Employee Data Sync histories ${companyName}.xlsx`
            );
            setReportBeingDownloaded(false);
          }
        );
      })
      .catch((err) => {
        toast("Error while downloading", { type: "error" });
        setReportBeingDownloaded(false);
      });
  };

  return (
    <VStack className="gap-4">
      <div className="flex items-baseline justify-between">
        <BCHeader
          className="items-baseline "
          bcTitle="Employee Record Sync Histories"
          bcDescription="View all your employee record sync histories"
        />
      </div>
      {syncConfigs && rowData.length === 0 && (
        <Box className="text-center justify-center align-middle">
          <img src={noDataImageUrl} alt="" className="h-44 my-5 w-56 mx-auto" />
          <p className="text-xxs pt-4">No Foreign data source(s) configured</p>
          <ButtonPrimary
            className="mt-4"
            onClick={() => {
              navigate(`/options/onboarding/employees`);
            }}
          >
            Configure Data source
          </ButtonPrimary>
        </Box>
      )}
      {syncConfigs && rowData.length > 0 && (
        <Box className="text-center justify-center align-middle m-4">
          <HStack className="items-center justify-between gap-4 bg-white p-4">
            <VStack>
              <p className="text-lg font-medium text-headerColor whitespace-nowrap">
                Sync Histories for the last 30 days
              </p>
            </VStack>
            <HStack>
              <BulkActions
                title="Trigger Sync"
                actions={actions.map((action) => ({
                  name: action,
                }))}
                onAction={(action) => handleBulkAction(action)}
              />
            </HStack>
          </HStack>
          <HStack className="justify-between w-full">
            <Box
              style={{
                height: `${
                  (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
                }px`,
              }}
              className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
            >
              <AgGridReact
                alwaysShowHorizontalScroll
                rowClass={
                  "border-t border-dashed cursor-pointer hover:bg-slate-50 "
                }
                alwaysMultiSort
                onGridReady={onGridReady}
                animateRows={true}
                defaultColDef={defaultColDef}
                rowData={rowData}
                suppressCopyRowsToClipboard={true}
                suppressCopySingleCellRanges={true}
                suppressCellFocus={true}
                suppressMenuHide={true}
                columnDefs={columnDefs}
                rowMultiSelectWithClick={true}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                pagination={true}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
                }
              ></AgGridReact>
            </Box>
          </HStack>
        </Box>
      )}
      {downloadDialogData.open && (
        <Dialog open={downloadDialogData.open} maxWidth="md">
          <div className="w-[800px] mx-auto bg-white rounded-lg">
            <div className="justify-between px-10 text-lg font-medium border-b py-7">
              <h6 className="flex justify-between">
                Download Sync Details
                <span
                  onClick={() =>
                    setDownloadDialogData({
                      ...downloadDialogData,
                      open: false,
                    })
                  }
                  className="cursor-pointer"
                >
                  X
                </span>
              </h6>
            </div>
            <VStack aria-label="header" className="gap-y-2 p-4">
              <HStack className="mt-4 ml-8 gap-x-8">
                <VStack className="w-1/3">
                  <Label>From Date</Label>
                  <Input
                    type="date"
                    onChange={(e) =>
                      setDownloadDialogData({
                        ...downloadDialogData,
                        fromDate: new Date(e.target.value),
                      })
                    }
                    min={
                      downloadDialogData?.minDate?.toISOString().split("T")[0]
                    }
                    max={maxDate?.toISOString().split("T")[0]}
                  />
                  {downloadDialogData && downloadDialogData.fromDate && (
                    <>
                      {downloadDialogData.minDate &&
                        downloadDialogData.fromDate.valueOf() <
                          (downloadDialogData.minDate?.valueOf() || 0) && (
                          <p className="text-xxs text-red-500">
                            From date cannot be lesser than{" "}
                            {downloadDialogData.minDate?.toDateString()}
                          </p>
                        )}
                      {downloadDialogData.fromDate.valueOf() >
                        (maxDate?.valueOf() || Infinity) && (
                        <p className="text-xxs text-red-500">
                          From date cannot be greater than{" "}
                          {maxDate?.toDateString()}
                        </p>
                      )}
                    </>
                  )}
                </VStack>
                <VStack className="w-1/3">
                  <Label>To Date</Label>
                  <Input
                    type="date"
                    onChange={(e) =>
                      setDownloadDialogData({
                        ...downloadDialogData,
                        toDate: new Date(e.target.value),
                      })
                    }
                    min={
                      downloadDialogData?.minDate?.toISOString().split("T")[0]
                    }
                    max={maxDate?.toISOString().split("T")[0]}
                  />
                  {downloadDialogData && downloadDialogData.toDate && (
                    <>
                      {downloadDialogData.toDate.valueOf() <
                        (downloadDialogData.minDate?.valueOf() || 0) && (
                        <p className="text-xxs text-red-500">
                          To date cannot be lesser than{" "}
                          {downloadDialogData.minDate?.toDateString()}
                        </p>
                      )}
                      {downloadDialogData.toDate.valueOf() >
                        (maxDate?.valueOf() || Infinity) && (
                        <p className="text-xxs text-red-500">
                          To date cannot be greater than{" "}
                          {maxDate?.toDateString()}
                        </p>
                      )}
                      {downloadDialogData.fromDate &&
                        downloadDialogData.toDate &&
                        downloadDialogData.toDate.valueOf() <
                          downloadDialogData.fromDate.valueOf() && (
                          <p className="text-xxs text-red-500">
                            To date cannot be lesser than from date:{" "}
                            {downloadDialogData.fromDate.toDateString()}
                          </p>
                        )}
                    </>
                  )}
                </VStack>
                <VStack className="w-1/3">
                  <Label>Datasource</Label>
                  <Select
                    options={actions}
                    onChange={(e) =>
                      setDownloadDialogData({
                        ...downloadDialogData,
                        datasource: e.target.value,
                      })
                    }
                  />
                </VStack>
              </HStack>
              <HStack className="justify-end gap-4">
                <ButtonSecondary>Cancel</ButtonSecondary>
                <ButtonPrimary
                  loading={reportBeingDownloaded}
                  onClick={downloadSyncHistory}
                >
                  Download
                </ButtonPrimary>
              </HStack>
            </VStack>
          </div>
        </Dialog>
      )}
    </VStack>
  );
}

export default SyncDetails;
