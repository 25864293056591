import { GrantedBy, VestingScheduleData } from "../store/useGrantDetailsStore";
import { PlanExercise } from "./Exercise";
import { PlanVesting } from "./Vest";
import { TriggerType } from "./VestingTemplate";

export type Grant = {
  planid: string;
  planName: string;
  planType: string;
  optionHolderId: string;
  grantIdentifier: string;
  optionHolderName: string;
  optionHolderState: string;
  optionsGranted: number;
  optionsVested: number;
  optionsLapsed: number;
  availableToAccelerate: number;
  grantPrice: number;
  optionsExercised: number;
  optionsExercisedWithCash: number;
  optionsExercisedWithStock: number;
  optionsForfeited: number;
  vestingTemplateName: string;
  grantDate: string;
  employeeId: string;
  employeeName: string;
  employeeIdentifier: string;
  isExerciseRequested: boolean;
  isExerciseAllowed: boolean;
  isVestRequested: boolean;
  vestingId: string;
  employmentStatus: string;
  vestingDate: string;
  vestingDateType: VestingDateType;
  vestingPeriod?: number;
  vestingInterval?: number;
  cliffPeriod: number;
  vestingTriggerType: TriggerType;
  grantLetterLink?: string;
  customizedGrantLetter: boolean;
  unVested?: number;
  netVested?: number;
  vestRequestDate?: Date;
  exerciseRequestDate?: Date;
  isFractional: boolean;
  availableStates: string[];
  isStateChangeAllowed: boolean;
  isWithdrawLapsed: boolean;
  isPausedVesting: boolean;
  actualVestingDay: string;
  vestings: PlanVesting[];
  exercises: PlanExercise[];
};

export type AddGrantReq = {
  id?: string;
  employeeId: string;
  employeeName: string;
  planId: string;
  planName: string;
  planType: string;
  optionsGranted: number;
  grantPrice: number;
  dateOfGrant: string;
  vestingDateType: VestingDateType;
  vestingTemplate: string;
  vestingId: string;
  vestingDate: string;
  grantType?: string;
  grantLetterType?: string | null;
  grantLetter?: string | null;
  grantLetterFileName?: string | null;
  customizedGrantLetter: boolean;
  actualVestingDay?: string;
  grantId?: string;
  grantedBY?: string;
  manuallyCreatedTemplate?: boolean;
  templateName?: string;
  vestingScheduleData?: VestingScheduleData[];
  sharePrice?: number;
  valuationId?: string;
  modellingId?: string;
  note?: string;
};

export enum VestingDateType {
  GRANT_DATE = "GRANT_DATE",
  EMPLOYEE_JOINING_DATE = "EMPLOYEE_JOINING_DATE",
  CUSTOM_DATE = "CUSTOM_DATE",
}

export interface AddGrantRes {
  optionHolderId: string;
}

export interface ReportDto {
  base64File: string;
  fileType: string;
}

export interface OptionStateChangeReq {
  optionHolderIds: string[];
  optionHolderState: string;
  lapseWithdrawed: boolean;
  note?: string;
  mailToBeSent: boolean;
}
export interface PlanStateChangeDto {
  esopPlanId: string;
  esopPlanState: string;
  planPoolSize: number;
  planStartDate: string;
  note?: string;
}

export interface GrantActivity {
  optionHolderId: string;
  optionHolderName: string;
  optionState: string;
  dateOfTransaction: string;
}

export interface EsopCreateDto {
  esopPoolCount: number;
}
export interface TrustDetails {
  id: string;
  trustName: string;
  trustPoolSize: number;
  availableShares: number;
}

export interface Template {
  s3link: string;
}

export interface CustomResponse<T> {
  data: T;
  message: string;
}
export interface UploadTemplate {
  uploadTemplate: string;
}

export interface CustomError<T> {
  data: any;
  errors: T;
}
export interface ValidateRes {
  vestingErrors: Errors[];
  employeeErrors: Errors[];
  grantErrors: Errors[];
  exerciseErrors: Errors[];
  basicValidationErrors: Errors[];
}

export interface Errors {
  id: string;
  name: string;
  errors: string;
}

export interface ImportRes {
  name: string;
  existingNumber: number;
  afterNumber: number;
  addedNumber: number;
}
export interface SignatureAttachment {
  signature: string;
  origin?: string;
  entityIds: number[] | string[];
}

export interface CreatePoolDto {
  poolSize: number;
  poolCreationDate: string;
}

export interface PoolDetailsDto {
  isPoolExists: boolean;
  poolSize: number;
  poolCreationDate: string;
}

export interface SettlementSignatureDto {
  signature: string;
  employeeId: string;
}

export interface UpdateSettlementLetterDto {
  fileUrl: string;
  base64file: string;
  employeeId: string;
}
export interface ValidationObj {
  name: string;
  value: any;
  error: any;
  valid: boolean;
}

export interface AccelerateVestingDto {
  currentVested: number;
  availableToAccelerate: number;
  grantId: string;
  numberOfOptionsToBeAccelerated: number;
  accelerateDate: string;
}

export interface OnboardingReportDto {
  file: string;
  dateTillVestingToBeDone: string;
}

export interface GrantValidation {
  hasErrors: boolean;
  fileUploaded: boolean;
  fileValidated: boolean;
  data: ValidationObj[][];
}

export interface GrantResponse {
  status: string;
  validationData: ValidationObj[][];
  message: string;
}

export interface DownloadConfig {
  fromDate?: Date;
  toDate?: Date;
  datasource?: string;
}
