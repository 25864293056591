import { Dialog } from "@mui/material";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  SideBarDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { getPresignedUrlForKey } from "../../api/Esop";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import SearchInput from "../../components/shared/SearchInput";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { Box, HStack, IconCTAButton, VStack } from "../../components/utils";
import folderLogo from "../../folder-document.svg";
import pdfLogo from "../../pdf.svg";
import { useDocuments } from "../../queries";
import BCHeader from "../../shared/BCHeader";
import GenericTableHeader from "../../shared/GenericTableHeader";
import { DocumentVault } from "../../types/DocumentsInterface";
import { normaliseValue } from "../../utils/agGridUtils";
import { CTADropdown } from "../../components/shared/Dropdown";
import { downloadS3File } from "../../utils/DownloadFile";

function DocumentManager() {
  const { data: documents } = useDocuments();
  const gridApi = useRef<GridApi | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [documentDialog, setDocumentDialog] = useState<{
    open: boolean;
    url?: string;
  }>({ open: false });
  const [view, setView] = useState("Folder View");
  const openToolPanel = (key: string) => {
    if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.openToolPanel(key);
        else gridApi?.current?.closeToolPanel();
        setIsFilterOpen((state) => !state);
      }
    }
  };

  useEffect(() => {
    if (documents !== undefined) {
      setFilteredRowData(documents);
    }
  }, [documents]);

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [shouldShowActions, setShouldShowActions] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };
  function makeFlat(documents: DocumentVault[]): DocumentVault[] {
    return _.uniqBy(
      _.flatMapDeep(documents, (doc) =>
        doc.isFolder ? makeFlat(doc.children) : [doc]
      ),
      (d) => d.name
    );
  }
  const [path, setPath] = useState<string[]>(["Home"]);
  const [data, setData] = useState<DocumentVault[]>([]);
  function handleDirectoryClick(key: string) {
    const children = data.find((d) => d.name === key)?.children;
    if (children) {
      setData(children);
      setPath((prev) => [...prev, key]);
    }
  }
  const handleAction = (item: DocumentVault) => {
    if (item.url) {
      getPresignedUrlForKey(item.url).then((res) => {
        downloadS3File(res.data, item.name);
      });
    }
  };
  useEffect(() => {
    setView("Folder View");
    setPath(["Home"]);
    setData(documents || []);
  }, [documents]);
  useEffect(() => {
    if (view === "Folder View") {
      setData(documents || []);
    } else {
      const flatStructure = makeFlat(documents || []);
      setData(flatStructure);
    }
    setPath(["Home"]);
  }, [view]);

  const rowData = useMemo(() => {
    const resultData: DocumentVault[] = [];
    data.forEach((item) => {
      const resultItem = { ...item };
      if (!item.isFolder) {
        resultItem.actions = (
          <CTADropdown
            dropdownClassName="xl:right-20 lg:right-18 md:right-12 -top-4"
            actions={[
              {
                name: "Download Document",
              },
            ]}
            onAction={(action) => {
              handleAction(item);
            }}
          />
        );
      }
      resultData.push(resultItem);
    });
    const allFolders = resultData.every((dataItem) => dataItem.isFolder);
    if (allFolders) {
      setShouldShowActions(false);
    } else {
      setShouldShowActions(true);
    }
    return resultData;
  }, [data]);

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "NAME",
      width: 200,
      autoHeight: true,
      cellStyle: { "padding-top": "15px" },
      wrapText: true,
      field: "name",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("name"),
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        suppressSelectAll: false,
        suppressMiniFilter: false,
        excelMode: "windows",
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.localeCompare(valueB);
      },
      menuTabs: ["filterMenuTab"],
      cellRenderer: FolderInfoRenderer,
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: !shouldShowActions,
      width: 80,
      maxWidth: 80,
      colId: "action-column",
      cellClass: "z-10",
      filter: false,
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      // cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRendererParams: ({ value }: { value: { props: any } }) =>
        value ? value.props : null,
      cellRenderer: CTADropdown,
    },
  ];
  interface CellRendererProps {
    data: DocumentVault;
  }

  function FolderInfoRenderer(props: CellRendererProps) {
    return (
      <HStack className="items-center gap-4">
        <img
          className="h-8"
          src={props.data.isFolder ? folderLogo : pdfLogo}
          alt={props.data.isFolder ? "folder" : "pdf"}
          color="#E85936"
        />
        <span>{props.data.name}</span>
      </HStack>
    );
  }
  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    const { column, data } = cellParams;
    if (column.getColId() !== "action-column") {
      if (data.isFolder) {
        handleDirectoryClick(data.name);
      } else if (data.url) {
        getPresignedUrlForKey(data.url).then((res) => {
          setDocumentDialog({ open: true, url: res.data });
        });
      }
    }
  };

  function handleBreadCrumb(currentIndex: number) {
    if (path.length === 1 && path[0] === "Home") {
      setData(documents || []);
      setPath(["Home"]);
    } else {
      let data = documents || [];
      for (let i = 1; i < currentIndex + 1; i++) {
        const index = data.findIndex((item) => item.name === path[i]);
        if (index !== -1) {
          data = data[index].children;
        }
      }
      setData(data);
      setPath(path.slice(0, currentIndex + 1));
    }
  }

  return (
    <VStack className="gap-4">
      <div className={`flex items-baseline justify-between`}>
        <BCHeader
          className="items-baseline"
          bcTitle="Document Vault"
          bcDescription="Access all employee related documents."
        />
      </div>
      <Box className="py-4 bg-white border rounded-lg min-w-min border-borderColor drop-shadow-box">
        <Box
          aria-label="toolbar"
          className={` flex flex-row justify-between
          mb-2`}
        >
          <VStack className="min-w-max">
            <GenericTableHeader
              heading={"Documents"}
              subHeading={""}
              additionalInfo={`${filteredRowData.length} results`}
            ></GenericTableHeader>
          </VStack>
        </Box>
        <HStack>
          {path.map((fold, index) => (
            <p
              className="cursor-pointer hover:underline hover:text-orange-501"
              key={fold}
              onClick={() => {
                if (view === "Folder View") handleBreadCrumb(index);
              }}
            >
              {index === 0 ? (
                <span
                  className={`pl-6 ${
                    index === path.length - 1 && "text-orange-501"
                  }
                  `}
                >
                  {fold}
                </span>
              ) : index !== path.length - 1 ? (
                `/${fold}`
              ) : (
                <span className="text-orange-501">{`/${fold}`}</span>
              )}
            </p>
          ))}
        </HStack>
        <HStack className="items-center justify-end gap-4">
          <HStack>
            <SearchInput
              placeholder={`Search`}
              onChange={(e) => {
                gridApi?.current?.setQuickFilter(e.target.value);
              }}
            />
          </HStack>
          <SwitchButton
            className={"cursor-default"}
            value={view !== "Folder View"}
            label="All Documents View"
            onClick={() => {
              if (view === "Folder View") {
                setView("Flat View");
              } else {
                setView("Folder View");
              }
            }}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 mr-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
        </HStack>
        <HStack className="justify-between w-full mt-1">
          <Box
            style={{
              height: `${(data.length >= 10 ? 10 : data.length + 3) * 60}px`,
            }}
            className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
          >
            <AgGridReact
              alwaysShowHorizontalScroll
              rowClass={
                "border-t border-dashed cursor-pointer hover:bg-slate-50 "
              }
              onFilterChanged={onAgGridFilterChanged}
              alwaysMultiSort
              onGridReady={onGridReady}
              sideBar={sideBar}
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              suppressCopyRowsToClipboard={true}
              suppressCopySingleCellRanges={true}
              suppressCellFocus={true}
              suppressMenuHide={true}
              columnDefs={columnDefs}
              onCellClicked={(e) => handleCellClick(e)}
              rowMultiSelectWithClick={true}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              overlayNoRowsTemplate={
                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
              }
            ></AgGridReact>
          </Box>
        </HStack>
      </Box>
      <Dialog
        open={documentDialog.open}
        onClose={() => setDocumentDialog({ open: false })}
        maxWidth="lg"
      >
        <LazyPdfDocument url={documentDialog?.url ?? ""} />
      </Dialog>
    </VStack>
  );
}
export default DocumentManager;
