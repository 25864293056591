import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { useBuybackOffers } from "../../queries/optionBuyback";
import {
  useAdvanceFilterDialog,
  useEditPaymentLinkDialog,
} from "../../store/useDialogStore";
import {
  MonetaryEventState,
  OptionBuyBackDetails,
} from "../../types/OptionBuyback";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import StatusLabel from "../esopOverview/StatusLabel";

function ExerciseDetails({
  buybackDetail,
}: {
  buybackDetail: OptionBuyBackDetails;
}) {
  const currencySymbol = getCurrencySymbol();
  const isUserEsopViewer = isEsopViewer();
  const currency = getCurrencyType();
  const navigate = useNavigate();

  const { id } = useParams();
  const _id = id ?? "";
  const { state: showAdvanceFilterDialog, setState: setShowAdvanceFilters } =
    useAdvanceFilterDialog();

  function editDetails() {
    navigate(`/options/edit-exercise-flow/${_id}?mode=edit`);
  }
  const { data: _buybackStateData, isPlaceholderData } = useBuybackOffers(_id);
  const buybackStateData = _buybackStateData || [];
  function getTotalEligibility() {
    let totalEligibility = 0;
    buybackStateData.forEach((item) => {
      totalEligibility += item.eligibility;
    });
    return totalEligibility;
  }
  function getTotalVestedShare() {
    let totalVestedShare = 0;
    buybackStateData.forEach((item) => {
      totalVestedShare += item.vested;
    });
    return totalVestedShare;
  }
  const { isMobile } = useIsMobile();
  const { state: showPaymentLinkDialog, setState: setShowPaymentLinkDialog } =
    useEditPaymentLinkDialog();
  function editLink(link: string) {
    setShowPaymentLinkDialog({ open: true, link, eventId: _id });
  }
  return (
    <VStack className={`${isPlaceholderData ? "loading" : ""} "gap-4 "`}>
      <Box
        className={`flex ${
          isMobile
            ? "flex-col"
            : "flex-row justify-between gap-12  items-center "
        }  p-4 bg-white border-b border-solid rounded`}
      >
        <Box className="flex items-center gap-12">
          <p
            className={`${
              isMobile ? "text-sm" : "text-lg"
            }  font-medium text-stone-700"`}
          >
            {buybackDetail?.eventName}
          </p>
          <StatusLabel state={buybackDetail?.eventState}></StatusLabel>
        </Box>
      </Box>
      <VStack
        className={`${
          isMobile ? "p-2" : "p-6"
        } gap-4 bg-[#fefefe] rounded m drop-shadow-md`}
      >
        <VStack className="gap-8">
          <Box
            className={` flex ${
              isMobile ? "flex-col gap-8" : "flex-row  justify-between gap-12"
            } `}
          >
            <div
              className={` flex flex-row items-center flex-1 gap-4 ${
                isMobile ? "justify-between " : " justify-start "
              } `}
            >
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full text-sm text-gray-600 flex-nowrap whitespace-nowrap flex-1`}
              >
                <p>Liquidity Program Name</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{buybackDetail?.eventName}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                <p>Description</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{buybackDetail?.description}</p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Sale Price </p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatCurrency(
                    buybackDetail?.monetaryDetails?.sellingPrice || 0,
                    currency
                  )}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Program start date</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatDisplayDate(buybackDetail?.startDate || "1979-01-01")}
                </p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Fair Market Value(FMV) Price</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatCurrency(
                    buybackDetail?.monetaryDetails?.fmvPrice || 0,
                    currency
                  )}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Effective Program date</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatDisplayDate(
                    buybackDetail?.vestingCutOffDate || "1979-01-01"
                  )}
                </p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                <p>Vested options</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{getTotalVestedShare().toLocaleString(currency)}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                <p>Program end date</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatDisplayDate(buybackDetail?.endDate || "1979-01-01")}
                </p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                {" "}
                <p> Eligible options</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{getTotalEligibility().toLocaleString(currency)}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start flex-1 gap-4">
              {buybackDetail?.eventPercentage > 0 ? (
                <>
                  <div
                    className={`${
                      isMobile ? "whitespace-nowrap" : ""
                    } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                  >
                    <p>Percentage (%)</p>
                  </div>
                  <div
                    className={`${
                      isMobile ? "text-end" : " "
                    } text-black bg-white flex-1 `}
                  >
                    <p>{(buybackDetail?.eventPercentage || 0) * 100}</p>
                  </div>
                </>
              ) : (
                <p>Offer list uploaded successfully</p>
              )}
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Estimated value</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatCurrency(
                    getTotalEligibility() *
                      (buybackDetail?.monetaryDetails.sellingPrice || 0),
                    currency
                  )}
                </p>
              </div>
            </div>
            <div
              className={`flex ${
                isMobile ? "whitespace-nowrap" : ""
              } w-full flex-1 text-sm flex-row items-center gap-4 text-gray-600 flex-nowrap whitespace-nowrap`}
            >
              <SwitchButton
                value={buybackDetail?.askWillingness}
                label={"Ask Willingness"}
                className="text-sm text-gray-600 cursor-default"
              />
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Miscellaneous Charges</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatCurrency(
                    buybackDetail?.monetaryDetails?.miscCharges || 0,
                    currency
                  )}
                </p>
              </div>
            </div>
            {buybackDetail?.monetaryDetails?.paymentLink ? (
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div
                  className={`${
                    isMobile ? "whitespace-nowrap" : ""
                  } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                >
                  {" "}
                  <p>Payment Link </p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <div className="flex flex-row gap-4 item-center">
                    <a
                      href={buybackDetail?.monetaryDetails?.paymentLink}
                      className="text-sm underline text-orange-501"
                    >
                      {`${buybackDetail?.monetaryDetails.paymentLink?.slice(
                        0,
                        10
                      )}...`}
                    </a>
                    <p
                      onClick={() =>
                        editLink(buybackDetail?.monetaryDetails.paymentLink)
                      }
                      className="cursor-pointer"
                    >
                      <Icon
                        icon="lets-icons:edit-duotone"
                        className="w-6 h-6"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-between flex-1 gap-4"></div>
            )}
          </Box>
          <p
            onClick={() => setShowAdvanceFilters({ open: true, id: _id })}
            className="p-2 px-5 py-2 font-normal border border-dashed rounded cursor-pointer max-w-min whitespace-nowrap h-11 text-sm1 focus:opacity-90 hover:bg-slate-100 border-orange-501"
          >
            Show Advance Filter
          </p>
        </VStack>
        <HStack className="flex-row-reverse self-end">
          {buybackDetail?.eventState === MonetaryEventState.DRAFT &&
          !isUserEsopViewer ? (
            <ButtonPrimary
              className="flex items-center self-end justify-center px-8 flex-nowrap"
              onClick={editDetails}
            >
              Edit Details
            </ButtonPrimary>
          ) : (
            ""
          )}
        </HStack>
      </VStack>
    </VStack>
  );
}

export default ExerciseDetails;
