import { ValidationObj } from "./Grant";

export type EmployeesOverviewDto = {
  employees: Employee[];
  departmentEmployeeCountDtos: DepartmentEmployeeCountDto[];
  yearofJoiningEmployeeCountDtos: YearofJoiningEmployeeCountDto[];
};

export interface UploadedData extends EmployeeUploadHeaderResponse {
  newEmployeesToBeAdded: NewEmployeesToBeAdded[];
  duplicateEmployees: DuplicateEmployee[];
}

export interface NewEmployeesToBeAdded {
  "Sl no": number;
  "Employee ID": string;
  "Employee Name": string;
  "Legal Name": string;
  "Status of employment": string;
  "Email address": string;
  "Employment Type": string;
  "Country Residing": string;
  "Registered address": string;
  Manager: string;
  "Employer Name": string;
  Grade: string;
  Designation: string;
  Department: string;
  Contact: string;
  Nationality: string;
  "Date Of Joining": number;
  "Date of Leaving"?: number;
}

export interface DuplicateEmployee {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  status: string;
  type: string;
  email: string;
  hrId: string;
  isLoginProvided: boolean;
  dateOfJoining: string;
  resignationDate: any;
  lastDay: any;
  companyId: string;
  previousEmployeeId: any;
  isDeactivated: boolean;
  isDeleted: boolean;
}

export type Employee = {
  employeeId?: string;
  id: string;
  employeeName: string;
  employeeIdentificationString: string;
  employeeDesignation: string;
  employmentStatus: string;
  department: string;
  email: string;
  totalOptionsGranted?: number;
  totalPercentageVested: number;
  lastDay?: string;
  dateOfJoin: string;
  isLoginProvided?: boolean;
  lastLogin?: string;
  employmentType: string;
  createdAt?: string;
  updatedAt?: string;
  noOfGrants?: number;
  salary?: number;
  isDeactivated: boolean;
  grade?: string;
  name?: string;
  isResignationSchedule?: boolean;
};

export type DepartmentEmployeeCountDto = {
  department: string;
  numberOfEmployees: number;
};

export type YearofJoiningEmployeeCountDto = {
  yearofJoining: number;
  numberOfEmployees: number;
};

export interface AddEmployeeReq {
  employeeName: string;
  employeeDesignation: string;
  address: string;
  contactNumber?: string;
  department: string;
  email: string;
  manager: string;
  residentialStatus: string;
  nationality: string;
  legalName: string;
  employeeIdentification: string;
  dateOfJoin: string;
  grade: string;
  employmentStatus: EmployementStatus;
  company: string;
  lastDay?: string;
  notes: string;
  employmentType: EmployementType;
  imageURL?: string;
  pan: string;
  personalEmail?: string;
  dematAccountId?: string;
  id?: string;
  subsidiaryCompanyId?: string;
  salary?: number;
  resignationDate?: string;
  employeeCount?: number; //Use only for modelling
}

export enum EmployementStatus {
  EMPLOYED = "EMPLOYED",
  RESIGNED = "RESIGNED",
  RESIGNATION_SCHEDULED = "RESIGNATION SCHEDULED",
  RETIRED = "RETIRED",
  TERMINATED = "TERMINATED",
  PENDING = "PENDING",
}

export enum EmployementType {
  EMPLOYEE = "EMPLOYEE",
  CONSULTANT = "CONSULTANT",
}

export interface RevokeAccessRequest {
  empId: string;
  isDeactived: boolean;
}

export interface RevokeAccessResponse {
  reason: string;
}

export interface UploadEmployeeExcel {
  file: string;
  correctedGrantData?: ValidationObj[][];
}
export interface UploadOfferExcel {
  file: string;
  eventId: string;
}

export interface UploadGrantExcel {
  file: string;
}

export type EmployeeCredentialGeneration = {
  employeeIds: string[];
  regenerateExisting: boolean;
  sendCredentialsGenerationEmail: boolean;
};

export type EmployeeUploadHeaderResponse = {
  headersInFile: string[];
  headersInSystem: string[];
  message?: string;
  sampleData?: { [k: string]: any };
  validationErrors?: any;
  cacheId?: string;
  validationData?: ValidationResponse[][];
  validationPending?: boolean;
  savePending?: boolean;
};

export type ValidateAndImportResponse = {
  hasErrors: boolean;
  validationData: ValidationResponse[][];
  validationPending: boolean;
};
export type ValidationResponse = {
  name: string;
  value: string | number;
  error: string | null;
  valid: boolean;
};
export type ImportConfig = {
  id: string;
  name?: string;
  mapperData: {
    [k: string]: { type: string; field: string };
  };
};
