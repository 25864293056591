import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi, SideBarDef } from "ag-grid-enterprise";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  HStack,
  IconCTAButton,
  VStack,
} from "../../components/utils";
import SearchInput from "../../components/shared/SearchInput";
import { ReportNames } from "./Reports";
import { sortComparator } from "../../utils/agGridUtils";
import ReportDialog from "./dialogs/ReportDialog";
import PoolStatementDialog from "./dialogs/PoolStatementDialog";
import SH6ReportDialog from "../esopOverview/SH6ReportDialog";
import FinancialReportDialog from "../esopOverview/FinancialReportDialog";
import GenericTableHeader from "../../shared/GenericTableHeader";
import { Input, Label } from "../../components/shared/InputField";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { downloadSync } from "../../api/Esop";
import { Select } from "../../components/shared/Select";
import { useGetAllCompanyConfigs } from "../../queries";

const ReportsAgGridTable = ({
  reportsData,
}: {
  reportsData: ReportNames[];
}) => {
  const [dialog, setDialog] = useState<{
    open: boolean;
    data: any;
  }>({ open: false, data: {} });
  const { data: syncConfigs, refetch: refetchConfigs } =
    useGetAllCompanyConfigs();
  const availableReports = useMemo(
    () =>
      syncConfigs && syncConfigs.length > 0
        ? reportsData
        : reportsData.filter(
            (data) => data.name !== "Employee Data Sync history"
          ),
    [syncConfigs]
  );
  const gridApi = useRef<GridApi | any>(null);
  function onGenerateButtonClicked(params: any) {
    const reportName = params?.data?.name;
    if (reportName === "Employee Data Sync history") {
      const earliestSync = _.minBy(syncConfigs, (config) => config.createdAt);
      const earliestSyncDate = earliestSync?.createdAt;
      if (earliestSyncDate) {
        const minDate = new Date(earliestSyncDate);
        minDate.setHours(0, 0, 0, 0);
        setDownloadDialogData({ ...downloadDialogData, minDate });
      }
    }
    setDialog({ open: true, data: params.data });
  }
  const generateButton = (params: any) => (
    <ButtonPrimary1
      onClick={() => {
        onGenerateButtonClicked(params);
      }}
    >
      Generate Report
    </ButtonPrimary1>
  );
  const actions = useMemo(
    () =>
      Array.from(
        new Set(
          syncConfigs?.map((config) =>
            config.masterDataType === "DarwinboxDataset"
              ? "Darwin Box"
              : config.masterDataType
          )
        )
      ),
    [syncConfigs]
  );
  useEffect(() => {
    if (availableReports !== undefined) {
      setFilteredRowData(availableReports);
    }
  }, [availableReports]);
  const [downloadDialogData, setDownloadDialogData] = useState<{
    open: boolean;
    fromDate?: Date;
    toDate?: Date;
    minDate?: Date;
    datasource?: string;
  }>({
    open: false,
  });
  const [reportBeingDownloaded, setReportBeingDownloaded] = useState(false);
  const maxDate = new Date();
  const sortComparator = (
    valueA: any,
    valueB: any,
    nodeA: any,
    nodeB: any,
    isInverted: any
  ) => {
    if (valueA === valueB) return 0;
    return valueA > valueB ? 1 : -1;
  };
  const downloadSyncHistory = () => {
    setReportBeingDownloaded(true);
    downloadSync({
      datasource: downloadDialogData.datasource,
      fromDate: downloadDialogData.fromDate,
      toDate: downloadDialogData.toDate,
    })
      .then((data) => {
        convertBase64ToBlob(data?.data?.base64File, data?.data?.fileType).then(
          (blobObject) => {
            downloadBlobObject(
              blobObject,
              `${downloadDialogData.datasource} sync histories.xlsx`
            );
            setReportBeingDownloaded(false);
            setDialog({ open: false, data: undefined });
          }
        );
      })
      .catch((err) => {
        toast("Error while downloading", { type: "error" });
        setReportBeingDownloaded(false);
      });
  };
  const columnDefs: ColDef[] = [
    {
      headerName: "NAME OF DOCUMENT",
      field: "name",
      cellStyle: (params: any) => ({
        fontWeight: 500,
      }),
      flex: 2,
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        suppressSelectAll: false,
        suppressMiniFilter: false,
        excelMode: "windows",
        comparator: sortComparator,
      },

      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "DESCRIPTION OF DOCUMENT",
      field: "description",
      flex: 6.5,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "GENERATE REPORT",
      field: "actions",
      cellRendererParams: ({ value }: { value: any }) => value,
      cellRenderer: generateButton,
      flex: 1.5,
      menuTabs: [],
    },
  ];

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 100,
      suppressMenu: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      comparator: sortComparator,
      minWidth: 100,
      resizable: true,
    }),
    []
  );

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const onGridReady = (params: any) => {
    gridApi.current = params;
  };

  return (
    <VStack className="w-full h-full">
      <HStack className="items-center justify-between gap-4">
        <GenericTableHeader
          heading={"Reports"}
          subHeading={""}
          additionalInfo={<>{`${filteredRowData?.length} reports`}</>}
        ></GenericTableHeader>
        <HStack className="gap-4">
          <HStack>
            <SearchInput
              className=""
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
          </HStack>
          {/* <QuickSearch
          className="px-2"
          placeholder="Search"
          onChange={(e) => {
            gridApi.current.setQuickFilter(e.target.value);
          }}
        /> */}
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isColumnOpen}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4  font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${reportsData.length * 60}px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onFilterChanged={onAgGridFilterChanged}
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={availableReports}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false, data: undefined })}
        maxWidth="lg"
      >
        {dialog.data?.name === "Pool Statement" ? (
          <div className="w-[400px] mx-auto bg-white rounded-lg  min-h-min">
            <PoolStatementDialog
              data={dialog.data}
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        ) : dialog.data?.name === "SH-6 Report" ? (
          <div className="mx-auto bg-white rounded-lg min-w-min min-h-min">
            <SH6ReportDialog
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        ) : dialog.data?.name === "Employee Data Sync history" ? (
          <div className="w-[800px] mx-auto bg-white rounded-lg">
            <div className="justify-between px-10 text-lg font-medium border-b py-7">
              <h6 className="flex justify-between">
                Download Sync Details
                <span
                  onClick={() => setDialog({ open: false, data: undefined })}
                  className="cursor-pointer"
                >
                  X
                </span>
              </h6>
            </div>
            <VStack aria-label="header" className="gap-y-2 p-4">
              <HStack className="mt-4 ml-8 gap-x-8">
                <VStack className="w-1/3">
                  <Label>From Date</Label>
                  <Input
                    type="date"
                    onChange={(e) =>
                      setDownloadDialogData({
                        ...downloadDialogData,
                        fromDate: new Date(e.target.value),
                      })
                    }
                    min={
                      downloadDialogData?.minDate?.toISOString().split("T")[0]
                    }
                    max={maxDate?.toISOString().split("T")[0]}
                  />
                  {downloadDialogData && downloadDialogData.fromDate && (
                    <>
                      {downloadDialogData.minDate &&
                        downloadDialogData.fromDate.valueOf() <
                          (downloadDialogData.minDate?.valueOf() || 0) && (
                          <p className="text-xxs text-red-500">
                            From date cannot be lesser than{" "}
                            {downloadDialogData.minDate?.toDateString()}
                          </p>
                        )}
                      {downloadDialogData.fromDate.valueOf() >
                        (maxDate?.valueOf() || Infinity) && (
                        <p className="text-xxs text-red-500">
                          From date cannot be greater than{" "}
                          {maxDate?.toDateString()}
                        </p>
                      )}
                    </>
                  )}
                </VStack>
                <VStack className="w-1/3">
                  <Label>To Date</Label>
                  <Input
                    type="date"
                    onChange={(e) =>
                      setDownloadDialogData({
                        ...downloadDialogData,
                        toDate: new Date(e.target.value),
                      })
                    }
                    min={
                      downloadDialogData?.minDate?.toISOString().split("T")[0]
                    }
                    max={maxDate?.toISOString().split("T")[0]}
                  />
                  {downloadDialogData && downloadDialogData.toDate && (
                    <>
                      {downloadDialogData.toDate.valueOf() <
                        (downloadDialogData.minDate?.valueOf() || 0) && (
                        <p className="text-xxs text-red-500">
                          To date cannot be lesser than{" "}
                          {downloadDialogData.minDate?.toDateString()}
                        </p>
                      )}
                      {downloadDialogData.toDate.valueOf() >
                        (maxDate?.valueOf() || Infinity) && (
                        <p className="text-xxs text-red-500">
                          To date cannot be greater than{" "}
                          {maxDate?.toDateString()}
                        </p>
                      )}
                      {downloadDialogData.fromDate &&
                        downloadDialogData.toDate &&
                        downloadDialogData.toDate.valueOf() <
                          downloadDialogData.fromDate.valueOf() && (
                          <p className="text-xxs text-red-500">
                            To date cannot be lesser than from date:{" "}
                            {downloadDialogData.fromDate.toDateString()}
                          </p>
                        )}
                    </>
                  )}
                </VStack>
                <VStack className="w-1/3">
                  <Label>Datasource</Label>
                  <Select
                    options={actions}
                    onChange={(e) =>
                      setDownloadDialogData({
                        ...downloadDialogData,
                        datasource: e.target.value,
                      })
                    }
                  />
                </VStack>
              </HStack>
              <HStack className="justify-end gap-4">
                <ButtonSecondary>Cancel</ButtonSecondary>
                <ButtonPrimary
                  loading={reportBeingDownloaded}
                  onClick={downloadSyncHistory}
                >
                  Download
                </ButtonPrimary>
              </HStack>
            </VStack>
          </div>
        ) : (
          <div className="w-[800px] mx-auto bg-white rounded-lg  min-h-min">
            <ReportDialog
              data={dialog.data}
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        )}
      </Dialog>
    </VStack>
  );
};

export default ReportsAgGridTable;
