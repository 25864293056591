import React from "react";
import { Box } from "../utils";
import { EmployementStatus } from "../../types/Employee";

function StatusIndicator({
  status,
  className,
}: {
  status: string;
  className: string;
}) {
  const getStatusColor = () => {
    if (status === EmployementStatus.RESIGNED) return "bg-red-500";
    if (status === EmployementStatus.RESIGNATION_SCHEDULED)
      return "bg-orange-500";
    if (status === EmployementStatus.EMPLOYED) return "bg-green-500";
    if (status === EmployementStatus.PENDING) return "bg-yellow-500";
    return "bg-blue-500";
  };
  return (
    <>
      {status && (
        <Box
          className={`${className} bottom-5 -right-2 flex w-5 h-5
        ${getStatusColor()}
      border-4 border-white border-solid rounded-full `}
        ></Box>
      )}
    </>
  );
}
export default StatusIndicator;
