import { Form, Formik, FormikProps } from "formik";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  Center,
  Error,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import {
  companyClassDropdownValues,
  currencyDropDownValues,
} from "../../constants/DropDownValues";
import {
  useCompanyProfileDetails,
  useGetAllCorporateActions,
  useUpdateCompanyProfile,
} from "../../queries/onBoarding";
import BCHeader from "../../shared/BCHeader";
import { usePermissionStore } from "../../store/permissionStore";
import { CompanyProfileDetails } from "../../types/SiteSettings";
import { determineUserAccessToResource } from "../../utils/auth";
import { formatDate } from "../../utils/date";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { initialCompanyDetail } from "./initValues";
import ImageResizer from "../../components/shared/ImageResizer";
import { useUserDetails } from "../../queries";
import AllCorporateActionDetails from "./AllCorporateActionDetails";
import { CorporateActionDetails } from "../../types/CompanyProfile";
import { AddOrEditBonusDetails } from "../../modals/AddOrEditBonusDetails";
import DropdownButton from "../../components/shared/DropdownButton";
import { Action } from "../../components/shared/Dropdown";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}
export function CompanyProfile() {
  const isUserEsopViewer = isEsopViewer();
  const { data: _companyDetail } = useCompanyProfileDetails();
  const { mutate: updateCompanyDetails, isLoading } = useUpdateCompanyProfile();
  const companyDetail = _companyDetail;
  const { data: user } = useUserDetails();
  const [isEdit, setIsEdit] = useState(false);
  const { permission } = usePermissionStore();
  const [imageResizerProps, setImageResizerProps] = useState<{
    open: boolean;
    imageUrl?: string;
  }>({ open: false, imageUrl: undefined });

  const getInitialValue = () =>
    companyDetail
      ? {
          id: companyDetail.id ?? "",
          name: companyDetail.name ?? "",
          cin: companyDetail.cin ?? "",
          companyCurrency: companyDetail.companyCurrency ?? "",
          dateOfIncorporation: formatDate(companyDetail.dateOfIncorporation),
          address: companyDetail.address ?? "",
          email: companyDetail.email ?? "",
          companyLogoPath: companyDetail.companyLogoPath ?? "",
          operatingAs: companyDetail.operatingAs ?? "",
          nic: companyDetail.nic ?? "",
          typeOfTheCompany: companyDetail.typeOfTheCompany ?? "",
          stockSymbol: companyDetail.stockSymbol ?? "",
        }
      : initialCompanyDetail;
  const handleCompanyDetailsSubmit = (values: CompanyProfileDetails) => {
    updateCompanyDetails(values, {
      onSuccess: () => {
        toast("Company Profile updated Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (error: { response: { data: { reason: string } } }) => {
        toast(`${error.response.data.reason}`, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };
  const validationCompanyDetailsSchema = Yup.object().shape({
    name: Yup.string().required("Company Name is required"),
    cin: Yup.string().required("CIN Number is required"),
    address: Yup.string().required("Company Registered Address is required"),
    dateOfIncorporation: Yup.string().required(
      "Date of Incorporation is required"
    ),
    email: Yup.string()
      .email("Enter Valid Company Email")
      .required("Company Email is required"),
    companyCurrency: Yup.string().required("Company Currency is required"),
    nic: Yup.string().required("Company NIC is required"),
    operatingAs: Yup.string().required("Doing Business As is required"),
    typeOfTheCompany: Yup.string().label("Class of the company"),
    stockSymbol: Yup.string().label("Stock Symbol"),
  });
  function handleEditImage(
    formikInstance: FormikProps<CompanyProfileDetails>
  ): void {
    setImageResizerProps({
      open: true,
      imageUrl: formikInstance.values.companyLogoPath,
    });
  }
  function handleImageSave(
    image: string,
    formikInstance: FormikProps<CompanyProfileDetails>
  ) {
    if (image) {
      formikInstance.setFieldValue("companyLogoPath", image);
    }
    setImageResizerProps({ open: false, imageUrl: undefined });
  }

  const { data, refetch: getAllCorporateActions } = useGetAllCorporateActions(
    _companyDetail?.id || ""
  );
  const { corporateActions } = data || { corporateActions: [] };

  const [showCorporateActions, setShowCorporateActions] = useState<boolean>();

  useEffect(() => {
    setShowCorporateActions(!(data?.userHasLimitedAccessToCompany || false));
  }, [data?.userHasLimitedAccessToCompany]);

  useEffect(() => {
    getAllCorporateActions();
  }, [_companyDetail?.id]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: CorporateActionDetails;
    mode?: "AddBonus" | "EditBonus" | "ApproveBonus";
  }>({
    open: false,
  });

  function handleCorporateAction(action: Action) {
    if (action.name === "Add Bonus" && !action.disabled) {
      setDialog({
        open: true,
        mode: "AddBonus",
      });
    }
  }

  function openBonusDialog(corporationAction: CorporateActionDetails) {
    setDialog({
      open: true,
      mode: "EditBonus",
      data: corporationAction,
    });
  }

  return (
    <div className="gap-4 mx-auto">
      <BCHeader className="items-baseline mb-4" bcTitle="Company profile" />
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "AddBonus" ||
        (dialog.mode === "EditBonus" && dialog.data) ? (
          <AddOrEditBonusDetails
            corporateAction={dialog.data}
            onClose={() => setDialog({ open: false })}
            mode={dialog.mode}
          />
        ) : dialog.mode === "ApproveBonus" ? (
          <></>
        ) : (
          <></>
        )}
      </Dialog>
      {showCorporateActions && (corporateActions || []).length > 0 && (
        <Box className="py-7">
          <AllCorporateActionDetails
            corporateActions={corporateActions}
            handleCorporateAction={handleCorporateAction}
            openBonusDialog={openBonusDialog}
          />
        </Box>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValue()}
        onSubmit={(values: CompanyProfileDetails) => {
          handleCompanyDetailsSubmit(values);
        }}
        validationSchema={validationCompanyDetailsSchema}
      >
        {(formik) => (
          <>
            <Form>
              <div className="w-full bg-white rounded-md">
                <Box className="px-10 py-4 text-lg font-medium text-left border-b">
                  <HStack className="justify-between">
                    <h6>Company Details</h6>
                    {showCorporateActions &&
                      (corporateActions || []).length === 0 && (
                        <DropdownButton
                          className="items-center text-sm"
                          actions={[
                            {
                              name: "Add Bonus",
                              disabled: false,
                            },
                          ]}
                          onAction={handleCorporateAction}
                          render={() => (
                            <div className="flex items-center rounded">
                              <p>Corporate Action</p>
                              <Icon
                                height={24}
                                icon="ri:arrow-drop-down-line"
                                className="inline"
                              />
                            </div>
                          )}
                          subtitle="Corporate Actions"
                        />
                      )}
                  </HStack>
                </Box>
                <VStack className="w-full py-4">
                  <VStack className="gap-4 mx-auto">
                    <Box className="relative w-32">
                      <Label>
                        <Icon
                          icon={"eva:edit-outline"}
                          onClick={() => isEdit && handleEditImage(formik)}
                          className={`${
                            !isEdit ? "cursor-not-allowed" : ""
                          } absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 hover:text-black`}
                        />
                      </Label>
                      {formik.values?.companyLogoPath === "" ? (
                        <Center className="relative w-32 h-32 bg-gray-100 ">
                          <Center className="flex w-32 h-32 rounded-full ">
                            <p className="text-base font-bold ">
                              {formik.values.name.charAt(0)}
                            </p>
                          </Center>
                        </Center>
                      ) : (
                        <img
                          className="block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                          src={formik.values.companyLogoPath}
                          alt="avatar"
                        />
                      )}

                      <Icon
                        icon={"eva:close-fill"}
                        // onClick={() => {
                        //   setImageUploadURL("");
                        // }}
                        className={`${
                          isEdit ? "cursor-not-allowed" : ""
                        } absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 -bottom-3 hover:text-black`}
                      />
                    </Box>
                  </VStack>
                </VStack>
                <VStack className="w-full gap-6 px-10 py-4">
                  <VStack className="gap-2">
                    <HStack className="gap-8">
                      <div className="flex-1">
                        <Label className="text-sm font-normal">
                          Name of the Company
                          <span className="text-rose-400">*</span>
                        </Label>
                        <Input
                          type="text"
                          disabled={!isEdit}
                          placeholder="Enter Company Name"
                          {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <Error text={formik.errors.name?.toString()} />
                        )}
                      </div>
                      <div className="flex-1">
                        <Label className="text-sm font-normal">
                          CIN Number
                          <span className="text-rose-400">*</span>
                        </Label>
                        <Input
                          type="text"
                          disabled={!isEdit}
                          className=""
                          {...formik.getFieldProps("cin")}
                        />
                        {formik.touched.cin && formik.errors.cin && (
                          <Error text={formik.errors.cin?.toString()} />
                        )}
                      </div>
                    </HStack>
                  </VStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Company Email ID
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Input
                        type="text"
                        disabled={!isEdit}
                        placeholder="Eg: example@domain.com"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <Error text={formik.errors.email?.toString()} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Date of Incorporation
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Input
                        type="date"
                        placeholder="Eg:12-12-1212"
                        disabled={!isEdit}
                        {...formik.getFieldProps("dateOfIncorporation")}
                      />
                      {formik.touched.dateOfIncorporation &&
                        formik.errors.dateOfIncorporation && (
                          <Error
                            text={formik.errors.dateOfIncorporation?.toString()}
                          />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Corporate Office Address
                      </Label>
                      <TextArea
                        placeholder="Enter Your Company Address"
                        disabled={!isEdit}
                        {...formik.getFieldProps("address")}
                      />
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Company Currency
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Select
                        options={currencyDropDownValues}
                        isPlaceHolderDisabled={true}
                        disabled={!isEdit}
                        {...formik.getFieldProps("companyCurrency")}
                      />
                      {formik.touched.companyCurrency &&
                        formik.errors.companyCurrency && (
                          <Error
                            text={formik.errors.companyCurrency?.toString()}
                          />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        NIC
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Input
                        type="text"
                        disabled={!isEdit}
                        {...formik.getFieldProps("nic")}
                      />
                      {formik.touched.nic && formik.errors.nic && (
                        <Error text={formik.errors.nic?.toString()} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Doing Business As
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Input
                        type="text"
                        disabled={!isEdit}
                        {...formik.getFieldProps("operatingAs")}
                      />
                      {formik.touched.nic && formik.errors.nic && (
                        <Error text={formik.errors.operatingAs?.toString()} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Class (Type Of The Company)
                        <span className="text-rose-400">*</span>
                      </Label>
                      <Select
                        options={companyClassDropdownValues}
                        valueGetter={(value) => value.value}
                        textGetter={(value) => value.label}
                        isPlaceHolderDisabled={true}
                        {...formik.getFieldProps("typeOfTheCompany")}
                      />
                      {formik.touched.typeOfTheCompany &&
                        formik.errors.typeOfTheCompany && (
                          <Error
                            text={formik.errors.typeOfTheCompany?.toString()}
                          />
                        )}
                    </div>
                    <div className="flex-1">
                      {formik.values.typeOfTheCompany === "PUBLIC" && (
                        <div className="flex-1">
                          <Label className="text-sm font-normal">
                            Stock Symbol
                          </Label>
                          <Input
                            type="text"
                            {...formik.getFieldProps("stockSymbol")}
                          />
                          {formik.touched.stockSymbol &&
                            formik.errors.stockSymbol && (
                              <Error
                                text={formik.errors.stockSymbol?.toString()}
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </HStack>
                </VStack>
              </div>
              {!isUserEsopViewer &&
                (isEdit ? (
                  <HStack className="justify-end m-8">
                    <ButtonPrimary onClick={() => setIsEdit(!isEdit)}>
                      Save
                    </ButtonPrimary>
                  </HStack>
                ) : (
                  <HStack className="justify-end m-8">
                    <ButtonPrimary
                      className={
                        !determineUserAccessToResource(
                          permission?.aclList || [],
                          Resource.Company,
                          DefaultAction.All
                        )
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }
                      type="submit"
                      onClick={() => setIsEdit(!isEdit)}
                      disabled={
                        !determineUserAccessToResource(
                          permission?.aclList || [],
                          Resource.Company,
                          DefaultAction.All
                        )
                      }
                      loading={isLoading}
                    >
                      Edit
                    </ButtonPrimary>
                  </HStack>
                ))}
              {imageResizerProps.open && (
                <Dialog maxWidth="lg" open={imageResizerProps.open}>
                  <div className="w-[600px]">
                    <ImageResizer
                      onCancel={() =>
                        setImageResizerProps({
                          ...imageResizerProps,
                          open: false,
                        })
                      }
                      onSave={(image: string) => handleImageSave(image, formik)}
                      imageUrl={imageResizerProps.imageUrl}
                    />
                  </div>
                </Dialog>
              )}
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}

export default CompanyProfile;
