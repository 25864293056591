import { useState } from "react";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { HStack, VStack } from "../../../components/utils";
import BCHeader from "../../../shared/BCHeader";
import CompanyDetailsStepper from "./CompanyDetailStepper";
import CompanyDetailsNavigation from "./CompanyDetailsNavigation";
import AlertDialog from "../../../components/shared/AlertDialog";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";

function CompanyDetails() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const a = Number(searchParams.get("id"));
  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({ open: false });
  const [currentStep, setCurrentStep] = useState(a || 1);
  const { selectedCompanyData } = useOrgTemplateStore();
  const { clear } = useOrgTemplateStore();
  function onStepChange(step: number) {
    if (step >= 4 && step <= 4.2) {
      if (step === 4) setCurrentStep(4.1);
      if (step === 4.1 && selectedCompanyData?.employeeListUploaded)
        setCurrentStep(4.2);
      if (step === 4.1 && !selectedCompanyData?.employeeListUploaded)
        setCurrentStep(5);
      if (step === 4.2) setCurrentStep(5);
    } else if (step === 3) {
      setCurrentStep(4.1);
    } else {
      setCurrentStep(step + 1);
    }
  }
  function onBackClick() {
    if (currentStep !== 1 && currentStep !== 5) {
      if (currentStep === 4.2) setCurrentStep(4.1);
      else setCurrentStep(parseInt(`${currentStep}`, 10) - 1);
    } else if (currentStep !== 1 && currentStep === 5) {
      setDialog({ open: true });
    } else if (currentStep === 1) {
      navigate(`/modelling/home`);
    }
  }
  return (
    <VStack className="h-full gap-4">
      <div className={`flex items-baseline justify-between`}>
        <BCHeader
          className="items-baseline"
          bcTitle="Modelling"
          bcSubTitle="Company Details"
          bcDescription="Enter the details of your company to start modelling your ESOP."
        />
      </div>
      <HStack className="flex h-full min-h-full gap-8 ">
        <VStack className="flex w-[30%] bg-white rounded-md min-w-72 ">
          <CompanyDetailsStepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </VStack>
        <VStack className="flex w-[70%] bg-white rounded-md min-w-96">
          <CompanyDetailsNavigation
            currentStep={currentStep}
            onStepChange={() => onStepChange(currentStep)}
            onBackClick={() => onBackClick()}
          />
        </VStack>
        <Dialog
          open={dialog.open}
          onClose={() => setDialog({ open: false })}
          maxWidth="lg"
        >
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message="Are you sure you want to Clear all data"
            onPrimaryAction={() => {
              clear();
              setDialog({ open: false });
              navigate("/modelling/home");
            }}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        </Dialog>
      </HStack>
    </VStack>
  );
}

export default CompanyDetails;
