import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import {
  CellEditorSelectorResult,
  ColDef,
  ICellEditorParams,
  ITooltipParams,
  ValueFormatterParams,
  GridApi,
  ValueSetterParams,
  CellClassParams,
  ValueGetterFunc,
  ValueGetterParams,
  SideBarDef,
} from "ag-grid-community";
import { AgGridReact, ICellEditorReactComp } from "ag-grid-react";
import { useNavigate } from "react-router";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  CellRenderer,
  HStack,
  IconCTAButton,
  ValidityCellRenderer,
  VStack,
} from "../../components/utils";
import {
  useEmployeeTemplate,
  useEmployeeUploadViaExcel,
  useGetEmployeeUploadConfigs,
  useValidateAndImportEmployee,
} from "../../queries";
import {
  AddEmployeeReq,
  EmployeeUploadHeaderResponse,
  UploadedData,
  ValidationResponse,
} from "../../types/Employee";
import convertToBase64 from "../../utils/convertToBase64";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import Tooltip from "../../components/shared/Tooltip";
import SearchDropDown from "../../components/shared/SearchDropdown";
import { formatDisplayDate } from "../../utils/date";
import { useOrgTemplateStore } from "../../store/useOrgTemplateStore";
import { downloadS3File } from "../../utils/DownloadFile";

interface ExcelImportProps {
  resetData: Function;
  usingForModelling?: boolean;
  onClose?: Function;
  onStepChange?: Function;
}
interface EmployeeTableDisplayProps {
  validationData: ValidationResponse[][];
  resetData: Function;
  usingForModelling?: boolean;
  onClose?: Function;
  onStepChange?: Function;
}
function ExcelImport(props: ExcelImportProps) {
  const {
    mutate: addEmployeesViaExcel,
    data: exceldata,
    isLoading: isExcelLoaded,
  } = useEmployeeUploadViaExcel();

  const template = useEmployeeTemplate();
  const templateFile = template.data ?? "";
  const _excelData = exceldata as unknown as UploadedData;
  const [uploadedData, setUploadedData] = useState<UploadedData>({
    duplicateEmployees: [],
    newEmployeesToBeAdded: [],
    headersInFile: [],
    headersInSystem: [],
    validationErrors: [],
    cacheId: undefined,
  });
  const [operationInProgress, setOperationInProgress] = useState(false);
  const { data: _employeeUploadConfigs } = useGetEmployeeUploadConfigs();
  const [newUploadConfig, setnewUploadConfig] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [validationData, setValidationData] = useState<
    Array<ValidationResponse[]>
  >([]);
  useEffect(() => {
    if (_excelData) {
      setUploadedData(_excelData);
    }
  }, [_excelData]);
  const availableConfigs = useMemo(() => {
    if (_employeeUploadConfigs === undefined) return [];
    return _employeeUploadConfigs;
  }, [_employeeUploadConfigs]);
  const [excelConfig, setExcelConfig] = useState<{
    headerRow: number;
    file: string;
    data?: any;
    rawHeaderData?: EmployeeUploadHeaderResponse;
    useExitingTemplate?: boolean;
    exitingMapperConfigId?: string;
    validationErrors?: any;
    sampleData?: { [k: string]: any };
    mapperData?: {
      [k: string]: { type: string; field: string };
    };
    name?: string;
    validationPending?: boolean;
    cacheId?: string;
    savePending?: boolean;
    usingForModelling: boolean;
  }>({
    file: "",
    headerRow: 1,
    usingForModelling: props.usingForModelling || false,
  });
  const uploadConfig = useMemo(
    () => _employeeUploadConfigs,
    [_employeeUploadConfigs]
  );
  const [configMetaData, setConfigMetaData] = useState({
    configName: "",
    existingConfigChanged: false,
    existingConfigSelected: false,
  });
  const handleConfigAdd = (index: number, field: string, value: string) => {
    const newConfig = new Map(newUploadConfig);
    newConfig.set(field, value);
    setnewUploadConfig(newConfig);
    if (excelConfig.useExitingTemplate && excelConfig.exitingMapperConfigId) {
      const tempConfig = new Map(newUploadConfig);
      const selectedConfig = availableConfigs.find(
        (config) => config.id === excelConfig.exitingMapperConfigId
      );
      Object.keys(selectedConfig?.mapperData || {}).forEach((data) => {
        const field = selectedConfig?.mapperData[data]?.field || "";
        tempConfig.set(field, data);
      });
      const didConfigChange = !_.isEqual(newConfig, tempConfig);
      setConfigMetaData({
        ...configMetaData,
        existingConfigChanged: didConfigChange,
      });
    }
  };
  async function handleExcelUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files?.[0];
    if (file) {
      const allowedExtensions = ["xlsx", "xls"];
      const splitFileName = file.name?.toLowerCase().split(".");
      const fileExtension = splitFileName[splitFileName.length - 1];
      if (!allowedExtensions.includes(fileExtension)) {
        toast(
          `Invalid file type, only ${allowedExtensions.toString()} are allowed`,
          { type: "error", autoClose: 3000 }
        );
        e.target.files = null;
        e.target.value = "";
        return;
      }
    }
    const base64: string = (await convertToBase64(file)) as string;
    const uploadObject = {
      file: base64,
      headerRow: excelConfig.headerRow,
      usingForModelling: props.usingForModelling || false,
    };
    setExcelConfig(uploadObject);
  }
  const resetData = () => {
    setOperationInProgress(false);
    setExcelConfig({
      file: "",
      headerRow: 1,
      usingForModelling: props.usingForModelling || false,
    });
    setUploadedData({
      duplicateEmployees: [],
      newEmployeesToBeAdded: [],
      headersInFile: [],
      headersInSystem: [],
      validationErrors: [],
      cacheId: undefined,
    });
    localStorage.removeItem("dataToBeUploaded");
    setnewUploadConfig(new Map<string, string>());
    setConfigMetaData({
      configName: "",
      existingConfigChanged: false,
      existingConfigSelected: false,
    });
    props.resetData();
  };
  const handleSubmit = async () => {
    setOperationInProgress(true);
    if (excelConfig.rawHeaderData) {
      const mapperData: { [k: string]: { type: string; field: string } } = {};
      Array.from(newUploadConfig.keys()).forEach((key) => {
        mapperData[newUploadConfig.get(key) || ""] = {
          type: "direct",
          field: key || "",
        };
      });
      excelConfig.mapperData = mapperData;
      if (
        !excelConfig.useExitingTemplate &&
        !excelConfig.exitingMapperConfigId &&
        !configMetaData.existingConfigChanged &&
        (!configMetaData.configName || configMetaData.configName === "")
      ) {
        toast("Config Template Name is required", {
          autoClose: 2000,
          type: "error",
        });
        setOperationInProgress(false);
        return;
      }
      if (
        excelConfig.useExitingTemplate &&
        excelConfig.exitingMapperConfigId &&
        configMetaData.existingConfigChanged &&
        (!configMetaData.configName || configMetaData.configName === "")
      ) {
        toast("Config Template Name is required", {
          autoClose: 2000,
          type: "error",
        });
        setOperationInProgress(false);
        return;
      }
      if (configMetaData.configName) {
        excelConfig.name = configMetaData.configName;
        excelConfig.exitingMapperConfigId = undefined;
        excelConfig.useExitingTemplate = undefined;
      }
    }
    addEmployeesViaExcel(excelConfig, {
      onSuccess: (data) => {
        setOperationInProgress(false);
        if (data.validationPending !== undefined && data.validationData) {
          setExcelConfig({
            ...excelConfig,
            validationPending: true,
            savePending: true,
          });
          setValidationData(data.validationData);
        } else if (data?.headersInFile && data?.headersInFile?.length > 0) {
          toast("Please Map respective fields", {
            autoClose: 2000,
            type: "info",
          });
          setExcelConfig({
            ...excelConfig,
            rawHeaderData: {
              headersInFile: data.headersInFile,
              headersInSystem: data.headersInSystem,
            },
            cacheId: data.cacheId,
            validationErrors: data.validationErrors,
            sampleData: data?.sampleData,
          });
        }
      },
      onError: (err) => {
        setOperationInProgress(false);
        const error = err.response.data.errorRaw;
        toast(error, { autoClose: 2000, type: "error" });
      },
    });
  };
  const handleConfigSelect = (configId: string) => {
    if (configId) {
      const selectedConfig = availableConfigs.find(
        (config) => config.id === configId
      );
      const keysInConfig = Object.values(selectedConfig?.mapperData || {}).map(
        (value) => value.field
      );
      const noMatchingItems = keysInConfig.every(
        (value) => !excelConfig.rawHeaderData?.headersInFile?.includes(value)
      );
      if (noMatchingItems) {
        toast("This config cannot be used as no matching headers found", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }
      const newConfig = new Map(newUploadConfig);
      Object.keys(selectedConfig?.mapperData || {}).forEach((data) => {
        const field = selectedConfig?.mapperData[data]?.field || "";
        newConfig.set(field, data);
      });
      setnewUploadConfig(newConfig);
      setExcelConfig({
        ...excelConfig,
        exitingMapperConfigId: configId,
        useExitingTemplate: true,
      });
      setConfigMetaData({ ...configMetaData, existingConfigSelected: true });
    }
  };
  async function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setConfigMetaData({ ...configMetaData, configName: e.target.value });
  }
  return (
    <VStack className="justify-between w-full h-full px-4 bg-white rounded-md min-h-[300px]">
      <HStack className="text-lg font-normal text-left ">
        <h6 className="flex-1">Excel Import</h6>
        {props.usingForModelling && (
          <ButtonPrimary onClick={() => downloadS3File(templateFile)}>
            Download Template
          </ButtonPrimary>
        )}
      </HStack>
      {uploadedData?.headersInFile?.length === 0 &&
        !excelConfig.validationPending && (
          <>
            <Box className="flex justify-center w-full px-10 text-lg font-medium">
              <h6 className="flex justify-between">
                Upload your Employee Excel
                <Tooltip
                  text={`Upload your excel sheet containing employee data`}
                  _className="w-[400px]"
                >
                  <Icon
                    icon="material-symbols:info-outline-rounded"
                    height={24}
                    width={24}
                    className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                  />
                </Tooltip>
              </h6>
            </Box>
            <HStack className="gap-8 pt-8 mx-auto">
              <div className="flex-1">
                <Label className="text-sm font-normal">Excel File</Label>
                <Input
                  disabled={excelConfig.rawHeaderData !== undefined}
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleExcelUpload}
                />
                <span className="py-2 italic text-gray-500 text-xxs">
                  Important: Please upload Excel sheets with no empty rows
                  before the header row for accurate data processing.
                </span>
              </div>
            </HStack>
          </>
        )}
      {uploadedData &&
        (excelConfig.rawHeaderData ||
          uploadedData?.headersInFile?.length !== 0) &&
        !excelConfig.validationPending && (
          <VStack className="py-8">
            <HStack className="items-center gap-8 mx-auto">
              <VStack className="w-full align-center">
                <Label className="inline-flex gap-4">
                  Use an Existing map{" "}
                  <Tooltip
                    text={`Select from a previously mapped config`}
                    _className="w-[400px]"
                  >
                    <Icon
                      icon="material-symbols:info-outline-rounded"
                      height={24}
                      width={24}
                      className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                    />
                  </Tooltip>
                </Label>
                <Select
                  options={availableConfigs.map((config) => config) || []}
                  name=""
                  disabled={(availableConfigs || []).length === 0}
                  textGetter={(data) => data.name || data.id}
                  valueGetter={(data) => data.id}
                  onChange={(e) =>
                    handleConfigSelect(e.target.value as unknown as string)
                  }
                  id=""
                ></Select>
              </VStack>
              {(configMetaData.existingConfigChanged ||
                !configMetaData.existingConfigSelected) && (
                <VStack className="w-full align-center">
                  <Label className="inline-flex gap-4 text-sm font-normal">
                    Config Template Name
                    <Tooltip
                      text={`Name of the config template to be saved`}
                      _className="w-[400px]"
                    >
                      <Icon
                        icon="material-symbols:info-outline-rounded"
                        height={24}
                        width={24}
                        className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                      />
                    </Tooltip>
                  </Label>
                  <Input
                    value={configMetaData?.configName}
                    type="text"
                    onChange={handleNameChange}
                  />
                </VStack>
              )}
            </HStack>
            <HStack className="gap-8 mx-10 pt-14">
              <table className="flex-1 w-full">
                <thead>
                  <tr></tr>
                  <tr>
                    <th>
                      <HStack className="items-center gap-2">
                        Excel Header
                        <Tooltip
                          text={`These are the headers in your Excel sheet `}
                          _className="w-[400px]"
                        >
                          <Icon
                            icon="material-symbols:info-outline-rounded"
                            height={24}
                            width={24}
                            className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                          />
                        </Tooltip>
                      </HStack>
                    </th>
                    <th>
                      <HStack className="items-center gap-2">
                        Hissa Fields
                        <Tooltip
                          text={`These are the fields on hissa`}
                          _className="w-[400px]"
                        >
                          <Icon
                            icon="material-symbols:info-outline-rounded"
                            height={24}
                            width={24}
                            className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                          />
                        </Tooltip>
                      </HStack>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedData?.headersInFile?.map((header, headerIndex) => (
                    <tr key={header}>
                      <td className="w-2/5 text-sm font-normal">
                        <Box>
                          <p>{header}</p>
                          <p className="text-xxs text-slate-dark">
                            eg: ({uploadedData?.sampleData?.[header]})
                          </p>
                        </Box>
                      </td>
                      <td className="w-2/5 p-2 text-sm font-normal">
                        <SearchDropDown
                          onChange={(e, value) => {
                            handleConfigAdd(headerIndex, header, value);
                          }}
                          key={`${header}-${headerIndex}`}
                          placeholder="-- Select Field --"
                          options={
                            uploadedData?.headersInSystem.filter(
                              (headerName) => headerName !== "companyId"
                            ) || [""]
                          }
                          value={newUploadConfig.get(header) || ""}
                          className={""}
                          getOptionLabel={(option) =>
                            `${
                              option.charAt(0).toUpperCase() + option.slice(1)
                            }`
                          }
                          isOptionEqualToValue={(option, value) =>
                            (option || "") === (value || "")
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </HStack>
          </VStack>
        )}
      {uploadedData && excelConfig.validationPending && validationData && (
        <>
          <EmployeeTable
            resetData={resetData}
            validationData={validationData}
            usingForModelling={props.usingForModelling || false}
            onClose={props.onClose}
            onStepChange={props.onStepChange}
          />
        </>
      )}
      {!(uploadedData && excelConfig.validationPending && validationData) && (
        <HStack className="justify-end gap-4 p-3">
          <ButtonPrimary1
            type="reset"
            className="text-red-500"
            onClick={() => {
              resetData();
            }}
          >
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary
            onClick={handleSubmit}
            type="submit"
            loading={operationInProgress}
          >
            Continue
          </ButtonPrimary>
        </HStack>
      )}
    </VStack>
  );
}
const EmployeeTable = (props: EmployeeTableDisplayProps) => {
  const {
    validationData: _validationData,
    resetData,
    onClose,
    onStepChange,
  } = props;
  const { addEmployeesDataToCompanyData } = useOrgTemplateStore();
  const { mutate: validateAndSaveData } = useValidateAndImportEmployee();
  const navigate = useNavigate();
  const gridApi = useRef<GridApi | any>(null);

  const [hasErrors, setHasErrors] = useState(false);
  const [validationData, columnNames] = useMemo(() => {
    let err = false;
    let columnNames: string[] = [];
    const validationData = _validationData.map((dataItem) => {
      let rowHasErr = false;
      dataItem.forEach((data) => {
        if (["dateOfJoining, lastDay"].includes(data.name))
          data.value = new Date(data.value).toDateString();
        if (data.error) {
          err = true;
          rowHasErr = true;
        }
      });
      return [...dataItem];
    });
    if (validationData.length > 0) {
      const keys = _.maxBy(validationData, Object.keys);
      columnNames = [
        "validity",
        ...Object.values(keys || {})
          .filter(
            (item) => !["companyId", "isPausedVesting"].includes(item?.name)
          )
          .map((value) => value?.name),
      ];
    }
    if (err) {
      toast("Please correct the errors before proceeding", {
        type: "error",
        autoClose: 2000,
      });
      setHasErrors(true);
    }
    return [validationData, columnNames];
  }, [_validationData]);
  useEffect(() => {
    if (gridApi.current) {
      gridApi.current?.api.setRowData(validationData);
    }
  }, [gridApi.current]);
  const [canSelectedBeImported, setCanSelectedBeImported] = useState(false);
  const checkErrors = (data: ValidationResponse[][], setErrors = true) => {
    const err = data
      .filter((dataItem) =>
        dataItem.find((dataEntity) => dataEntity.name !== "validity")
      )
      .some((dataItem) => dataItem.some((data) => data.valid === false));
    if (setErrors) {
      if (err) {
        setHasErrors(true);
      } else {
        setHasErrors(false);
      }
    }

    return err;
  };
  const proceedToImport = (all = true) => {
    setOperationInProgress(true);
    let data: ValidationResponse[][] = [];
    if (all) {
      gridApi.current?.api?.forEachNode((node: any) => {
        data.push(node.data);
      });
    } else {
      const selectedRows = gridApi.current?.api?.getSelectedRows();
      if (selectedRows.length === 0) {
        toast("No Rows Selected", {
          autoClose: 2000,
          type: "error",
        });
        setOperationInProgress(false);
        return;
      }
      data = selectedRows;
    }
    if (data.length > 0) {
      if (!props.usingForModelling) {
        validateAndSaveData(data, {
          onSuccess: (data) => {
            if (data.hasErrors) {
              toast("Data still has errors", {
                autoClose: 2000,
                type: "error",
              });
              data.validationData?.forEach((dataItem) => {
                let rowHasErr = false;
                dataItem.forEach((data) => {
                  if (["dateOfJoining, lastDay"].includes(data.name))
                    data.value = new Date(data.value).toDateString();
                  if (data.error) {
                    rowHasErr = true;
                  }
                });
              });
              gridApi.current?.setRowData(data.validationData);
              gridApi.current?.refreshCells({ force: true });
              checkErrors(data.validationData);
              setOperationInProgress(false);
            } else {
              toast("Data Imported Successfully, you'll now be redirected", {
                autoClose: 1000,
                type: "success",
              });
              setOperationInProgress(false);
              setTimeout(() => {
                navigate("/options/allEmployees");
              }, 2000);
            }
          },
          onError: (err) => {
            const error = err.response.data.toString();
            toast(error, { autoClose: 2000, type: "error" });
          },
        });
      } else {
        const employeesList: AddEmployeeReq[] = [];
        data.forEach((validEmployee) => {
          const returnData: { [key: string]: string | number } = {};
          validEmployee.forEach((detail) => {
            returnData[detail.name] = detail.value;
          });
          employeesList.push(returnData as unknown as AddEmployeeReq);
        });
        addEmployeesDataToCompanyData(employeesList);
        if (onClose && onStepChange) {
          onClose();
          onStepChange();
        }
      }
    } else setOperationInProgress(false);
  };
  const [operationInProgress, setOperationInProgress] = useState(false);
  const cellEditorSelector: (
    params: ICellEditorParams<any>
  ) => CellEditorSelectorResult | undefined = (
    params: ICellEditorParams<any>
  ) => {
    const field = params.colDef.field;
    switch (field) {
      case "employmentStatus": {
        return {
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            values: ["EMPLOYED", "RESIGNED"],
          },
        };
      }
      case "employmentType": {
        return {
          component: "agRichSelectCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            values: ["EMPLOYEE", "CONSULTANT"],
          },
        };
      }
      case "dateOfJoining":
      case "dateOfJoin":
      case "lastDay": {
        return {
          component: "agDateCellEditor",
          params: {
            allowTyping: true,
            filterList: true,
            highlightMatch: true,
            valueListMaxWidth: 150,
            min: "1900-01-01",
            max: "2100-12-31",
          },
        };
      }
      default: {
        return undefined;
      }
    }
  };
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const defaultColDef = useMemo<ColDef>(
    () => ({
      checkboxSelection: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const getCellStyle = (params: CellClassParams<any, any>) => {
    const colId = params.column.getColId();
    if (colId === "validity") {
      if (params.data.find((dataItem: { valid: boolean }) => !dataItem.valid))
        return { color: "red", backgroundColor: "unset" };
      return { color: "black", backgroundColor: "unset" };
    } else {
      return params?.value?.valid
        ? { color: "black", backgroundColor: "unset" }
        : { color: "red", backgroundColor: "unset" };
    }
  };
  function setValueAndResetCell(params: ValueSetterParams) {
    const dataItem = params?.data;
    if (params.newValue === undefined || params.newValue === null) return false;
    dataItem.forEach((data: any) => {
      if (data.name === params.colDef.field) {
        if (data.value !== params.newValue) {
          data.value = params.newValue;
          data.valid = true;
          data.error = null;
        }
      }
    });
    params.node?.setData(dataItem);
    const data: any[] = [];
    gridApi.current.api?.forEachNode((node: any) => {
      data.push(node.data);
    });
    checkErrors(data);
    gridApi.current?.api.refreshCells();
    return true;
  }
  function isRowValid(rowData: ValidationResponse[]) {
    const err = rowData
      ?.filter((dataItem) => dataItem.name !== "validity")
      ?.some((dataItem) => !dataItem.valid);
    return !err;
  }
  const valueGetter = (params: ValueGetterParams<any, any>) => {
    const colId = params.column.getColId();
    const data = params?.data?.find(
      (dataItem: any) => dataItem.name === colId
    ) || { value: undefined, valid: true };
    if (colId === "validity") {
      const isRowDataValid = isRowValid(params?.data);
      const validityValue: ValidationResponse = {
        name: "validity",
        valid: isRowDataValid,
        value: isRowDataValid ? "Valid" : "Invalid",
        error: isRowDataValid ? null : "Validation Error",
      };
      return validityValue;
    }
    if ((colId === "dateOfJoining" || colId === "lastDay") && data?.value) {
      data.value = formatDisplayDate(data?.value as unknown as string);
    }
    return data;
  };
  const valueFormatter = (params: ValueFormatterParams<any, any>) => {
    const item = params.column.getColId();
    if (item === "dateOfJoining" || item === "lastDay") {
      if (!params.value.value) {
        return "";
      }
      const formattedDate = formatDisplayDate(
        params.value.value as unknown as string
      );
      return formattedDate;
    }
    return "";
  };
  const columnDefs: ColDef[] = useMemo(
    () =>
      columnNames?.map((item, index) => {
        const colDef: ColDef = {
          headerName: item,
          checkboxSelection: index === 0,
          valueGetter,
          field: item,
          cellRenderer:
            item === "validity" ? ValidityCellRenderer : CellRenderer,
          cellRendererParams: ({ value }: { value: any }) => value,
          cellEditorSelector,
          cellStyle: getCellStyle,
          valueFormatter,
          valueSetter: setValueAndResetCell,
          filterValueGetter: (params) => {
            const data = params.data?.find(
              (dataItem: any) => dataItem.name === item
            );
            if (item === "validity") {
              return isRowValid(params?.data || []) ? "Valid" : "Invalid";
            }
            return data?.value;
          },
          cellEditorParams: (params: any) => {
            const field = params.colDef.field;
            if (field === "dateOfJoining" || field === "lastDay") {
              const data = {
                ...params.value,
                value: new Date(params.value.value),
              };
              return data;
            }
            return params.value;
          },
          headerClass: "text-sm font-normal capitalize",
          cellDataType: item === "dateOfJoining" ? "date" : "string",
          editable: true,
          pinned:
            item === "validity" || item === "employeeName" ? "left" : undefined,
        };
        return colDef;
      }),
    [columnNames]
  );

  const onGridReady = (params: any) => {
    gridApi.current = params;
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
    params.columnApi.getAllColumns().map((column: any) => column.colId);
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();
    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  return (
    <VStack className="justify-between w-full h-full bg-white rounded-md min-h-[500px]">
      <li className="text-xxs">
        Validity of Entry is denoted in the `Validity` Column
      </li>
      <li className="text-xxs">Make changes if necessary</li>
      <li className="text-xxs">Click on Import All or Import Selected</li>
      <HStack className="items-center justify-end gap-4">
        <IconCTAButton
          value={"Filters"}
          onClick={() => openToolPanel("filters")}
          iconName={"material-symbols:filter-alt"}
          className={`px-4  font-medium items-center flex flex-row ${
            isFilterOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isFilterOpen}
        />
      </HStack>
      <div className="py-4 ag-theme-material" style={{ height: 500 }}>
        <AgGridReact
          key={"EMPLOYEEIMPORTGRID"}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={"multiple"}
          rowClass={"border-t border-dashed"}
          suppressRowClickSelection={true}
          onSelectionChanged={() => {
            const selectedRows = gridApi.current?.api?.getSelectedRows();
            if ((selectedRows || []).length === 0) {
              setCanSelectedBeImported(false);
              return;
            }
            const err = checkErrors(selectedRows, false);
            if (err) {
              setCanSelectedBeImported(false);
            } else {
              setCanSelectedBeImported(true);
            }
          }}
          onFilterChanged={onAgGridFilterChanged}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          suppressRowTransform={true}
          animateRows={true}
          pagination={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          suppressMenuHide={true}
          sideBar={sideBar}
          rowMultiSelectWithClick={true}
          stopEditingWhenCellsLoseFocus={true}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
          }
        />
      </div>
      <HStack className="justify-end gap-4 p-3">
        <ButtonPrimary1
          type="reset"
          className="text-red-500"
          onClick={() => {
            resetData();
          }}
        >
          Discard
        </ButtonPrimary1>
        {!operationInProgress && (
          <ButtonPrimary disabled={hasErrors} onClick={() => proceedToImport()}>
            Import All
          </ButtonPrimary>
        )}
        {!operationInProgress && (
          <ButtonPrimary
            disabled={!canSelectedBeImported}
            onClick={() => proceedToImport(false)}
          >
            Import Selected
          </ButtonPrimary>
        )}
        {operationInProgress && (
          <Icon
            className="animate-spin text-orange-501"
            icon="lucide:loader-2"
            width={36}
          />
        )}
      </HStack>
    </VStack>
  );
};

export default ExcelImport;
