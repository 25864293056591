import { useMutation, useQuery } from "react-query";
import {
  updateCompanyProfile,
  createEsopCompany,
  getCompanyProfileDetails,
  getAllCorporateActions,
  generateBonusReport,
  approveBonus,
  discardCorporateAction,
  uploadDocumentTemplate,
} from "../api/onBoarding";
import { queryClient } from "./client";
import { getEmployeesOverview } from "../api/Esop";

export function useCompanyProfileDetails() {
  return useQuery({
    queryKey: "getCompanyProfileDetails",
    queryFn: async () => (await getCompanyProfileDetails()).data,
  });
}

export function useUpdateCompanyProfile() {
  return useMutation({
    mutationKey: "updateCompanyProfile",
    mutationFn: updateCompanyProfile,
    onSuccess: () => {},
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useCreateESOPCompany() {
  return useMutation({
    mutationKey: "createCompany",
    mutationFn: createEsopCompany,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
}

export function useGetAllCorporateActions(companyId: string) {
  return useQuery({
    queryKey: ["getAllCorporateActions", companyId],
    queryFn: async () => {
      const response = await getAllCorporateActions(companyId);
      return response;
    },
  });
}

export function useGenerateBonusReport() {
  return useMutation({
    mutationKey: "generateBonusReport",
    mutationFn: generateBonusReport,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllCorporateActions");
    },
  });
}

export function useApproveBonus() {
  return useMutation({
    mutationKey: "approveBonus",
    mutationFn: approveBonus,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllCorporateActions");
    },
  });
}

export function useDiscardCorporateAction() {
  return useMutation({
    mutationKey: "discardCorporateAction",
    mutationFn: discardCorporateAction,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllCorporateActions");
    },
  });
}

export function useUploadDocumentTemplate() {
  return useMutation({
    mutationKey: "uploadDocumentTemplate",
    mutationFn: uploadDocumentTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllCorporateActions");
    },
  });
}
