import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { Box, HStack, VStack } from "../../components/utils";
import { useGetAllCorporateActions } from "../../queries/onBoarding";
import { useCompanyStore } from "../../store";
import CorporateActionDetailsCard from "./CorporateActionDetailsCard";
import { CorporateActionDetails } from "../../types/CompanyProfile";
import DropdownButton from "../../components/shared/DropdownButton";
import { Action } from "../../components/shared/Dropdown";

function AllCorporateActionDetails({
  corporateActions,
  handleCorporateAction,
  openBonusDialog,
}: {
  corporateActions?: CorporateActionDetails[];
  handleCorporateAction: (action: Action) => void;
  openBonusDialog: Function;
}) {
  return (
    <Box className="w-full bg-white rounded-md">
      <Box className="">
        <VStack className="justify-between">
          <Box className="border-b px-10 py-4 font-medium text-left">
            <HStack className="justify-between">
              <h6 className="text-lg">Corporate Actions</h6>
              <DropdownButton
                className="items-center"
                actions={[
                  {
                    name: "Add Bonus",
                    disabled: false,
                  },
                ]}
                onAction={handleCorporateAction}
                render={() => (
                  <div className="flex items-center rounded">
                    <p>Corporate Actions</p>
                    <Icon
                      height={24}
                      icon="ri:arrow-drop-down-line"
                      className="inline"
                    />
                  </div>
                )}
                subtitle="Corporate Actions"
              />
            </HStack>
          </Box>
          <VStack className="p-8 px-15 py-4 gap-4">
            {corporateActions?.map((corporateAction) => (
              <div key={corporateAction.name}>
                <CorporateActionDetailsCard
                  corporateAction={corporateAction}
                  openBonusDialog={openBonusDialog}
                />
              </div>
            ))}
          </VStack>
        </VStack>
      </Box>
    </Box>
  );
}

export default AllCorporateActionDetails;
