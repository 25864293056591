import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import {
  Box,
  ButtonPrimary1,
  ButtonSecondary,
  getCompanyName,
  HStack,
  VStack,
} from "../../components/utils";
import { CorporateActionDetails } from "../../types/CompanyProfile";
import { formatDisplayDate } from "../../utils/date";
import { downloadS3File } from "../../utils/DownloadFile";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import StatusLabel from "../esopOverview/StatusLabel";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { useDiscardCorporateAction } from "../../queries/onBoarding";
import Tooltip from "../../components/shared/Tooltip";

function CorporateActionDetailsCard({
  corporateAction,
  openBonusDialog,
}: {
  corporateAction: CorporateActionDetails;
  openBonusDialog: Function;
}) {
  const currency = getCurrencyType();

  const { mutate: discardCorporateAction } = useDiscardCorporateAction();

  const handleAction = (action: Action) => {
    if (action.name === "Discard") {
      discardCorporateAction(corporateAction.id || "", {
        onSuccess: (data) => {
          toast("Record Discarded Successfully", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (error) => {
          toast("Error Discarding Record", {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  };

  return (
    <Box className="w-full bg-white shadow-md rounded-md">
      <Box className="px-10 py-4 border-b">
        <HStack className="justify-between">
          <HStack className="gap-3 text-lg font-medium text-left items-center">
            <h5>{corporateAction.name}</h5>
            <StatusLabel state={corporateAction.state || ""} />
          </HStack>
          <CTADropdown
            dropdownClassName=""
            actions={[
              {
                name: "Discard",
                disabled: corporateAction.state === "APPROVED",
              },
            ]}
            onAction={(action) => handleAction(action)}
          />
        </HStack>
        <HStack className="py-4 pt-3 space-between items-center">
          <VStack className="w-full pr-5">
            <HStack className="items-center">
              <h5 className="text-lg font-medium text-left">
                1:{corporateAction.ratio}
              </h5>
              <p className="text-xs font-semi-medium text-gray-light px-3 whitespace-nowrap">
                ({corporateAction.ratio} bonus option(s) for every 1 option)
              </p>
            </HStack>
            <p className="text-sm font-semi-medium text-gray-light">
              Bonus Ratio
            </p>
          </VStack>
          <VStack className="w-full">
            <h5 className="text-lg font-medium text-left">
              {formatDisplayDate(corporateAction.date)}
            </h5>
            <p className="text-sm font-semi-medium text-gray-light">
              Bonus Date
            </p>
          </VStack>
          {corporateAction.bonusStrikePrice && (
            <VStack className="w-full">
              <h5 className="text-lg font-medium text-left">
                {formatCurrency(corporateAction.bonusStrikePrice, currency)}
              </h5>
              <p className="text-sm font-semi-medium text-gray-light">
                Bonus Strike Price
              </p>
            </VStack>
          )}
          {corporateAction.s3url && (
            <VStack className="w-full">
              <BonusSummaryReport s3url={corporateAction?.s3url || ""} />
              <p className="text-sm font-semi-medium text-gray-light pl-7">
                Bonus Summary Report
              </p>
            </VStack>
          )}
          {corporateAction.state === "DRAFT" && (
            <VStack className="pl-10">
              <ButtonPrimary1
                onClick={() => {
                  //Open Edit Bonus Details
                  openBonusDialog(corporateAction);
                }}
              >
                Details
              </ButtonPrimary1>
            </VStack>
          )}
        </HStack>
      </Box>
    </Box>
  );
}

export function BonusSummaryReport({ s3url }: { s3url: string }) {
  const bonusReportName = `${getCompanyName()}_Bonus_Summary.xlsx`;
  return (
    <Tooltip text={"Click to download Report"}>
      <div
        className="cursor-pointer"
        onClick={() => {
          downloadS3File(s3url || "", bonusReportName);
        }}
      >
        <HStack className="items-center">
          <Icon icon="mdi:file-excel" width={32} color="#51A687" />
          <p className="text-sm font-semi-medium hover:text-red-600 underline">
            Bonus_Summary.xlsx
          </p>
        </HStack>
      </div>
    </Tooltip>
  );
}

export default CorporateActionDetailsCard;
