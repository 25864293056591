import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import SearchInput from "../../../components/shared/SearchInput";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  IconCTAButton,
  VStack,
} from "../../../components/utils";
import DepartmentLabel from "./DepartmentLabel";
import OrganiSationStructureDataProcessor, {
  CompanyData,
  HiringPlanData,
  YearlyData,
} from "../companyDetails/utils/OrgStructureJSONDataProc";
import { getSalaryRangeForUI } from "../modellingUtils";
import { useCreateHiringPlan } from "../../../queries/hiringPlan";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import "./hiringPlanTable.css";
import { useOrgTemplateStore } from "../../../store/useOrgTemplateStore";
import GenericTableHeader from "../../../shared/GenericTableHeader";

interface HiringPlanTableProps {
  onclickedNext: Dispatch<SetStateAction<boolean>>;
  hiringPlanData: HiringPlanData;
  setHiringPlanData: Dispatch<SetStateAction<HiringPlanData>>;
}

type LevelInfoDepartmentCell = {
  name: string;
  level: string;
};

type TotalInfoCellProps = {
  currentCount: number;
  totalNewHires: number;
};

export default function HiringPlanTable({
  onclickedNext,
  hiringPlanData,
  setHiringPlanData,
}: Readonly<HiringPlanTableProps>) {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const { selectedCompanyData } = useOrgTemplateStore();
  const navigate = useNavigate();
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params;
    params.api.sizeColumnsToFit();
    const initialData = params.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(initialData);
  };
  function onPageChange() {
    const updatedData = updateModelData();
    setHiringPlanData(updatedData);
    onclickedNext(false);
  }
  const { mutate: createHiringPlan, isLoading } = useCreateHiringPlan();
  let departmentIndex = 0;
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: false,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );

  const LevelInfoCell: React.FC<LevelInfoDepartmentCell> = ({
    name,
    level,
  }) => {
    departmentIndex = departmentIndex > 12 ? 0 : departmentIndex + 1;
    return (
      <VStack className="w-full align-left">
        <HStack>
          <span className="pt-1 text-xs font-medium text-gray-dark">
            {level}
          </span>
        </HStack>
        <HStack className="items-center pb-2 ">
          <DepartmentLabel
            name={name}
            index={departmentIndex}
            _className="leading-0"
          />
        </HStack>
      </VStack>
    );
  };

  const TotalInfoCell: React.FC<TotalInfoCellProps> = ({
    totalNewHires,
    currentCount,
  }) => (
    <VStack className="w-full pt-2">
      <HStack>
        <span className="text-xs font-medium p text-gray-dark">
          <b>New Hires:</b> {totalNewHires}
        </span>
      </HStack>
      <HStack className="items-center pt-1">
        <span className="text-xs text-gray-400">Previous: {currentCount}</span>
      </HStack>
    </VStack>
  );
  const getColumnDef = () => {
    const data: ColDef[] = [
      {
        headerName: "LEVELS",
        field: "level",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params: any) => params.value.name,
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agSetColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.data.levelName.type,
              // filterParams: {
              //   buttons: ["reset"],
              //   valueGetter: (params: any) => params.data.levelName.type,
              // },
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const eventStatus = params.data?.levelName.type;
                  return eventStatus;
                },
                valueFormatter: (params: any) => {
                  const eventStatus = params.data?.levelName.type;
                  return eventStatus;
                },
                valueGetter: (params: any) => params.data.levelName.type,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const eventStatus = params.data?.levelName.name;
                  return eventStatus;
                },
                valueFormatter: (params: any) => {
                  const eventStatus = params.data?.levelName.name;
                  return eventStatus;
                },
                valueGetter: (params: any) => params.data.levelName.name,
              },
            },
          ],
        },
        minWidth: 250,
        sortable: true,
        autoHeight: true,
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        cellRendererParams: ({ value }: { value: { props: any } }) =>
          value.props,
        cellRenderer: LevelInfoCell,

        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "ROLES",
        autoHeight: true,
        field: "role",
        filter: "agSetColumnFilter",
        minWidth: 200,
        cellStyle: {
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
        filterParams: {
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          buttons: ["reset"],
        },

        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "SALARY",
        autoHeight: true,
        field: "salaryRange",
        filter: "agSetColumnFilter",
        minWidth: 200,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "CURRENT COUNT",
        autoHeight: true,
        field: "currentCount",
        filter: "agNumberColumnFilter",
        minWidth: 200,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        editable: !selectedCompanyData?.employeeListUploaded,
        ...(!selectedCompanyData?.employeeListUploaded && {
          cellClass: "hiringPlanEditor",
        }),
        valueParser: (params) => {
          const parsedValue = parseInt(params.newValue, 10);
          return Number.isNaN(parsedValue) ? null : parsedValue;
        },
        valueSetter: (params) => {
          const parsedValue = parseInt(params.newValue, 10);
          if (!Number.isNaN(parsedValue)) {
            params.data[params.colDef.field || ""] = parsedValue;
            return true;
          }
          return false;
        },
      },
      {
        headerName: "Department",
        autoHeight: true,
        field: "department",
        filter: "agSetColumnFilter",
        flex: 1,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        hide: true,
      },
    ];

    for (let i = 1; i <= hiringPlanData.noOfYears; i++) {
      data.push({
        headerName: `YEAR ${i}`,
        autoHeight: true,
        field: `YEAR ${i}`,
        editable: true,
        cellEditor: "agNumberCellEditor",
        flex: 1,
        filter: "agNumberColumnFilter",
        minWidth: 200,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        cellClass: "hiringPlanEditor",
        valueParser: (params) => {
          const parsedValue = parseInt(params.newValue, 10);
          return Number.isNaN(parsedValue) ? null : parsedValue;
        },
        valueSetter: (params) => {
          const parsedValue = parseInt(params.newValue, 10);
          if (!Number.isNaN(parsedValue)) {
            params.data[params.colDef.field || ""] = parsedValue;
            return true;
          }
          return false;
        },
      });
    }
    data.push({
      headerName: "TOTAL",
      autoHeight: true,
      field: "total",
      filter: "agTextColumnFilter",
      minWidth: 200,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      filterValueGetter: (params: any) => {
        const { currentCount, totalNewHires } = params.data.total.props;
        return `${currentCount} ${totalNewHires}`;
      },
      cellRenderer: TotalInfoCell,
    });
    return data;
  };

  const updateModelData = () => {
    let hiringData = _.cloneDeep(hiringPlanData);
    for (const rd of rowData) {
      let yearlyData = {};
      let totalEmployeeCount = 0;
      let currentCount = 0;
      Object.entries(rd).forEach(([key, value]) => {
        if (key.includes("YEAR")) {
          yearlyData = { ...yearlyData, [key]: value };
          totalEmployeeCount += Number(value);
        }
        if (key.includes("current")) {
          currentCount += Number(value);
          totalEmployeeCount += Number(value);
        }
      });

      hiringData = {
        ...hiringData,
        data: {
          employeeListUploaded: hiringData.data?.employeeListUploaded || false,
          teamSize: 0,
          companyName: hiringData.data?.companyName || "",
          ...hiringData.data,
          departments: {
            ...hiringData.data?.departments,
            [rd.department]: {
              ...hiringData.data?.departments[rd.department],
              levels: {
                ...hiringData.data?.departments[rd.department].levels,
                [rd.level.props.level]: {
                  ...hiringData.data?.departments[rd.department].levels[
                    rd.level.props.level
                  ],
                  yearlyData,
                  currentEmployeeCount: rd.currentCount,
                  totalEmployeeCount,
                  ...(selectedCompanyData?.employeeListUploaded && {
                    futureHires: {
                      employeeName: "Future Hires",
                      employeeDesignation: "",
                      employeeIdentification: "",
                      grade: rd.level.props.level,
                      department: rd.department,
                      salary: parseInt(
                        (
                          (Number(
                            hiringData.data?.departments[rd.department].levels[
                              rd.level.props.level
                            ].salaryRange.max
                          ) +
                            Number(
                              hiringData.data?.departments[rd.department]
                                .levels[rd.level.props.level].salaryRange.min
                            )) /
                          2
                        ).toString(),
                        10
                      ),
                      employeeCount: totalEmployeeCount - currentCount,
                    },
                  }),
                },
              },
            },
          },
        },
      };
    }
    return hiringData;
  };

  const getRowData = () => {
    const data = [];
    if (hiringPlanData.data) {
      const processedData = new OrganiSationStructureDataProcessor(
        undefined,
        hiringPlanData.data
      );
      const levelData = processedData.getDataByLevel();
      for (const level of levelData) {
        const currentCount =
          hiringPlanData.data.departments[level.department]?.levels[level.level]
            ?.currentEmployeeCount || 0;

        const rowData: any = {
          levelName: {
            name: level.department,
            type: level.level,
          },
          level: <LevelInfoCell name={level.department} level={level.level} />,
          role: level.role,
          department: level.department,
          salaryRange: getSalaryRangeForUI(
            level.salaryRange,
            currencySymbol,
            currencyType
          ),
          currentCount,
        };
        let total = 0;
        for (let j = 1; j <= hiringPlanData.noOfYears; j++) {
          const yearTotal =
            hiringPlanData.data.departments[level.department]?.levels[
              level.level
            ]?.yearlyData &&
            Object.prototype.hasOwnProperty.call(
              hiringPlanData.data.departments[level.department]?.levels[
                level.level
              ]?.yearlyData,
              `YEAR ${j}`
            )
              ? hiringPlanData.data.departments[level.department]?.levels[
                  level.level
                ]?.yearlyData[`YEAR ${j}`]
              : 0;
          total += yearTotal;
          rowData[`YEAR ${j}`] = yearTotal;
        }
        rowData.total = (
          <TotalInfoCell currentCount={currentCount} totalNewHires={total} />
        );
        data.push(rowData);
      }
    }

    return data;
  };

  const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
    const { data, colDef, newValue } = event;
    if (colDef.field === "currentCount") {
      data.total = (
        <TotalInfoCell
          currentCount={parseInt(newValue, 10)}
          totalNewHires={data.total.props.totalNewHires}
        />
      );
    }
    if (colDef.field?.includes("YEAR")) {
      let total = 0;
      const key = colDef.field;
      Object.entries(data).forEach(([name, value]) => {
        if (name.includes("YEAR")) {
          total += parseInt(value as unknown as string, 10);
        }
      });
      data.total = (
        <TotalInfoCell
          totalNewHires={total}
          currentCount={data.total.props.currentCount}
        />
      );
    }
    gridApi?.current?.api?.applyTransaction({ update: [data] });
    gridApi?.current?.api?.refreshCells({ force: true });
  }, []);

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const columnDefs = getColumnDef();

  const rowData = useMemo(() => getRowData(), [hiringPlanData.data]);

  function handleSave() {
    const updatedData = updateModelData();
    setHiringPlanData(updatedData);
    createHiringPlan(updatedData, {
      onSuccess: () => {
        toast("Successfully Created Hiring Plan", {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/modelling/home`);
      },
      onError: () => {
        toast("Error while creating Hiring Plan", {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  }

  const [filteredRowData, setFilteredRowData] = useState<any>();
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  return (
    <VStack className="w-full px-4 py-4 bg-white">
      <HStack className="items-center justify-between gap-4">
        <GenericTableHeader
          heading={"Hiring Plan"}
          count={filteredRowData?.length}
          subHeading={"results"}
          additionalInfo={"Summary of hiring plan created"}
        ></GenericTableHeader>
        <HStack className="gap-4">
          <HStack>
            <SearchInput
              className=""
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
          </HStack>
          {/* <QuickSearch
          className="px-2"
          placeholder="Search"
          onChange={(e) => {
            gridApi.current.setQuickFilter(e.target.value);
          }}
        /> */}
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isColumnOpen}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4  font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
        </HStack>
      </HStack>

      <HStack className="justify-between w-full pt-6 overflow-x-auto">
        <Box
          style={{
            height: `${
              rowData.length <= 3
                ? "300"
                : rowData.length >= 10
                ? "600"
                : rowData.length * 80
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onFilterChanged={onAgGridFilterChanged}
            alwaysShowHorizontalScroll
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            onCellValueChanged={onCellValueChanged}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
      <HStack className="justify-between gap-4 p-4 pl-10 row-reverse">
        <ButtonPrimary1
          className="border-orange-501"
          onClick={() => {
            onPageChange();
          }}
        >
          Back
        </ButtonPrimary1>
        <ButtonPrimary
          className="min-w-max "
          onClick={() => handleSave()}
          loading={isLoading}
        >
          Save
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
}
