import create from "zustand";
import { configurePersist } from "zustand-persist";
import { VestingDateType } from "../types/Grant";
import { Employee } from "../types/Employee";
import { EsopPlan } from "../types/EsopPlan";
import { VestingTemplate } from "../types/VestingTemplate";

const { persist: _persist } = configurePersist({
  rootKey: "root",
  storage: localStorage,
});

const persist = _persist as any;

type AddGrantReq = {
  id?: string;
  employeeId: string;
  employeeName: string;
  planId: string;
  planName: string;
  planType: string;
  optionsGranted: number;
  grantPrice: number;
  dateOfGrant: string;
  vestingDateType: VestingDateType;
  vestingTemplate: string;
  vestingId: string;
  vestingDate: string;
  grantLetterType?: string | null;
  grantLetter?: string | null;
  grantLetterFileName?: string | null;
  customizedGrantLetter: boolean;
  actualVestingDay?: string;
};

type CustomGrantLetter = {
  fileName: string;
  fileType: string;
  file: string;
  link?: string;
  customizedLetter: boolean;
};

export type VestingScheduleData = {
  vestingDate: string;
  percentage: number;
  vestingOptions: number;
  milestoneId?: string;
  sequenceNumber?: number;
};

export enum GrantedBy {
  AMOUNT = "AMOUNT",
  OPTIONS = "OPTION",
  MODEL = "MODEL",
}

export interface NewGrantRes {
  id?: string;
  employee: Employee;
  plan: EsopPlan;
  grantedBy: GrantedBy;
  grantId: string;
  conversionRatio: number;
  dateOfGrant: string;
  grantPrice: number;
  optionsGranted: number;
  valuationId: string;
  modellingId: string;
  sharePrice: number;
  amount: number;
  valueOfOptions: number;
  modelValueOfOptions: string;
  modelMaxValueOfOptions: number;
  optionMultiplier: number;
  minSalary: number;
  grantType: string;
  maxSalary: number;
  vestingTemplate: VestingTemplate;
  vestingDateType: VestingDateType;
  actualVestingDay: string;
  vestingDate: string;
  vestingScheduleData: VestingScheduleData[];
  selectedExistingTemplate: boolean;
  customizedGrantLetter: CustomGrantLetter;
  emailsToSend: boolean;
  manualTemplateName: string;
  templateToEdit: undefined;
  note?: string;
}

const initialState = {
  employee: undefined,
  plan: undefined,
  grantedBy: GrantedBy.OPTIONS,
  grantId: "",
  grantType: "",
  conversionRatio: "",
  dateOfGrant: "",
  grantPrice: 0,
  optionsGranted: 0,
  valuationId: "",
  modellingId: "",
  sharePrice: 0,
  amount: 0,
  valueOfOptions: 0,
  modelValueOfOptions: "",
  modelMaxValueOfOptions: 0,
  optionMultiplier: 0,
  minSalary: 0,
  maxSalary: 0,
  vestingTemplate: undefined,
  vestingDateType: VestingDateType.GRANT_DATE,
  actualVestingDay: "SAME_DAY",
  vestingDate: undefined,
  vestingScheduleData: [],
  selectedExistingTemplate: true,
  customizedGrantLetter: {},
  emailsToSend: false,
  manualTemplateName: "",
  templateToEdit: undefined,
  note: "",
};

type GrantStoreState = {
  employee: Employee;
  setEmployee: (emp: Employee) => void;
  plan: EsopPlan;
  setPlan: (plan: EsopPlan) => void;
  grantedBy: string;
  setGrantedBy: (type: string) => void;
  grantId: string;
  setGrantId: (id: string) => void;
  grantType: string;
  setGrantType: (type: string) => void;
  conversionRatio: string;
  setConversionRatio: (text: string) => void;
  dateOfGrant: string;
  setDateOfGrant: (date: string) => void;
  grantPrice: number;
  setGrantPrice: (no: number) => void;
  optionsGranted: number;
  setOptionsGranted: (no: number) => void;
  valuationId: string;
  setValuationId: (id: string) => void;
  modellingId: string;
  setModellingId: (id: string) => void;
  sharePrice: number;
  setSharePrice: (no: number) => void;
  amount: number;
  setAmount: (no: number) => void;
  valueOfOptions: number;
  setValueOfOptions: (no: number) => void;
  modelValueOfOptions: string;
  modelMaxValueOfOptions: number;
  setModelValueOfOptions: (no: string) => void;
  setModelMaxValueOfOptions: (no: number) => void;
  optionMultiplier: number;
  setOptionMultiplier: (no: number) => void;
  minSalary: number;
  setMinSalary: (no: number) => void;
  maxSalary: number;
  setMaxSalary: (no: number) => void;
  vestingTemplate: VestingTemplate;
  setVestingTemplate: (vesting: VestingTemplate) => void;
  templateToEdit: VestingTemplate;
  setTemplateToEdit: (vesting: VestingTemplate) => void;
  vestingDateType: VestingDateType;
  setVestingDateType: (type: VestingDateType) => void;
  actualVestingDay: string;
  setActualVestingDay: (type: string) => void;
  manualTemplateName: string;
  setManualTemplateName: (type: string) => void;
  vestingDate: string;
  setVestingDate: (type: string) => void;
  customizedGrantLetter: CustomGrantLetter;
  setCustomizedGrantLetter: (type: CustomGrantLetter) => void;
  selectedExistingTemplate: boolean;
  setSelectedExistingTemplate: (type: boolean) => void;
  vestingScheduleData: VestingScheduleData[];
  setVestingScheduleData: (data: VestingScheduleData[]) => void;
  addVestingScheduleData: (data: VestingScheduleData) => void;
  deleteVestingScheduleData: (index: number) => void;
  emailsToSend: boolean;
  setEmailsTosend: (b: boolean) => void;
  clear: () => void;
  note: string;
  setNote: (note: string) => void;
  changeVestingScheduleData: (
    index: number,
    value: string | number,
    field: string
  ) => void;
};

export const useGrantDetailsStore = create<GrantStoreState>(
  persist(
    {
      key: "grantDetails",
      name: "grantDetails",
      storage: sessionStorage,
    },
    (set: any) => ({
      ...initialState,
      setEmployee: (emp: string) => set({ employee: emp }),
      setPlan: (plan: EsopPlan) => set({ plan }),
      setGrantedBy: (type: GrantedBy) => set({ grantedBy: type }),
      setGrantId: (id: string) => set({ grantId: id }),
      setGrantType: (type: string) => set({ grantType: type }),
      setDateOfGrant: (date: string) => set({ dateOfGrant: date }),
      setGrantPrice: (price: number) => set({ grantPrice: price }),
      setConversionRatio: (text: string) => set({ conversionRatio: text }),
      setOptionsGranted: (no: number) => set({ optionsGranted: no }),
      setValuationId: (id: string) => set({ valuationId: id }),
      setModellingId: (id: string) => set({ modellingId: id }),
      setSharePrice: (no: number) => set({ sharePrice: no }),
      setAmount: (no: number) => set({ amount: no }),
      setValueOfOptions: (no: number) => set({ valueOfOptions: no }),
      setModelValueOfOptions: (no: string) => set({ modelValueOfOptions: no }),
      setModelMaxValueOfOptions: (no: number) =>
        set({ modelMaxValueOfOptions: no }),
      setOptionMultiplier: (no: number) => set({ optionMultiplier: no }),
      setMinSalary: (no: number) => set({ minSalary: no }),
      setMaxSalary: (no: number) => set({ maxSalary: no }),
      setSelectedExistingTemplate: (type: boolean) =>
        set({ selectedExistingTemplate: type }),
      setEmailsTosend: (type: boolean) => set({ emailsTosend: type }),
      setVestingTemplate: (vesting: VestingTemplate) =>
        set({ vestingTemplate: vesting }),
      setTemplateToEdit: (vesting: VestingTemplate) =>
        set({ templateToEdit: vesting }),
      setVestingDateType: (type: VestingDateType) =>
        set({ vestingDateType: type }),
      setManualTemplateName: (type: string) =>
        set({ manualTemplateName: type }),
      setActualVestingDay: (type: string) => set({ actualVestingDay: type }),
      setVestingDate: (type: string) => set({ vestingDate: type }),
      setVestingScheduleData: (data: VestingScheduleData[]) => {
        set({ vestingScheduleData: data });
      },
      setNote: (note: string) => set({ note }),
      setCustomizedGrantLetter: (data: CustomGrantLetter) =>
        set({ customizedGrantLetter: data }),
      addVestingScheduleData: (data: VestingScheduleData) => {
        set((state: GrantStoreState) => ({
          vestingScheduleData: [...(state.vestingScheduleData || []), data],
        }));
      },
      deleteVestingScheduleData: (index: number) => {
        set((state: GrantStoreState) => ({
          vestingScheduleData: state.vestingScheduleData
            ? state.vestingScheduleData.filter((_, i) => i !== index)
            : [],
        }));
      },
      changeVestingScheduleData: (
        index: number,
        value: string | number,
        field: string
      ) => {
        set((state: GrantStoreState) => ({
          vestingScheduleData: state.vestingScheduleData.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
          ),
        }));
      },
      clear() {
        set((state: GrantStoreState) => initialState);
      },
    })
  )
);
