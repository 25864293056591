import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  getCompanyName,
  HStack,
  VStack,
} from "../../../components/utils";
import { Input, Label } from "../../../components/shared/InputField";
import { Select } from "../../../components/shared/Select";
import { MultiSelectV2 } from "../../../components/shared/MultiSelectV2";
import { ReportType } from "../utils/report";
import { OptionTypes } from "../../../components/shared/MultiSelect";
import { useEsopPlans } from "../../../queries";
import { PlanState } from "../../../types/EsopPlan";
import { generateGrantReport } from "../../../api/Esop";
import { ReportDto } from "../../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../../utils/DownloadFile";
import { useGetOptionBuyback } from "../../../queries/optionBuyback";
import {
  EventType,
  MonetaryTransactionType,
} from "../../../types/OptionBuyback";
import { SwitchButton } from "../../../components/shared/SwitchButton";
import Tooltip from "../../../components/shared/Tooltip";

const ReportDialog = ({
  onClose = () => {},
  data,
}: Readonly<{
  onClose: () => void;
  data: any;
}>) => {
  const { data: _plans } = useEsopPlans();
  const { data: _details } = useGetOptionBuyback();
  const plans = _plans || [];
  const events = _details || [];
  const planOptions: OptionTypes[] = [];
  const buyBackEvents: OptionTypes[] = [];
  const liquidityEvents: OptionTypes[] = [];
  const ids: string[] = [];
  const buyBackIds: string[] = [];
  const liquidityIds: string[] = [];
  plans.forEach((plan) => {
    planOptions.push({ value: plan.planName });
    ids.push(plan.esopPlanId);
  });
  events.forEach((event) => {
    if (event.eventType === EventType.CASH) {
      buyBackEvents.push({ value: event.eventName });
      buyBackIds.push(event.id || "");
    } else if (event.eventType === EventType.LIQUIDITY) {
      liquidityEvents.push({ value: event.eventName });
      liquidityIds.push(event.id || "");
    }
  });

  const [loading, setLoading] = useState(false);

  const dateTypes = [
    "As of Date",
    "Current Financial Year",
    "Previous Financial Year",
    "Custom Interval",
  ];
  const grantStates: OptionTypes[] = [
    { value: "CREATED" },
    { value: "FOR_APPROVAL" },
    { value: "APPROVED" },
    { value: "OFFERED" },
    { value: "GRANTED" },
    { value: "WITHDRAW" },
  ];
  const offerStates: OptionTypes[] = [
    { value: "DRAFT" },
    { value: "OFFERED" },
    { value: "EXPIRED" },
    { value: "ACCEPTED" },
    { value: "REJECTED" },
    { value: "PENDING_EXERCISED" },
    { value: "COMPLETED" },
  ];
  const employmentStates: OptionTypes[] = [
    { value: "EMPLOYED" },
    { value: "RESIGNED" },
  ];
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);
  const [selectedDateType, setSelectedDateType] = useState("");

  const initialValues: ReportType = {
    grantStates: [],
    planIds: [],
    employeeStates: [],
    previewReport: false,
    grantIds: [],
    startDate: undefined,
    endDate: undefined,
    offerStates: [],
    eventIds: [],
    eventType: [],
    vestingCutOffDate: undefined,
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setLoading(true);

      if (data?.name === "Liquidity Report") {
        values.eventType = [
          MonetaryTransactionType.EXERCISE_LIQUIDITY,
          MonetaryTransactionType.LIQUIDATION,
        ];
      }
      if (data?.name === "BuyBack Report") {
        values.eventType = [MonetaryTransactionType.BUYBACK];
      }
      if (values.startDate === "") {
        values.startDate = undefined;
      }
      if (values.endDate === "") {
        values.endDate = undefined;
      }
      if (values.vestingCutOffDate === "") {
        values.vestingCutOffDate = undefined;
      }
      try {
        const res = await data?.onSubmitFunction(values);
        await downloadReport(res.data);
      } catch (err: any) {
        toast(
          err?.response?.data?.errors || "Error while downloading the report",
          { type: "error", autoClose: 2000 }
        );
      } finally {
        setLoading(false);
      }
    },
  });
  const fileName = `${data?.name}_${getCompanyName()}.xlsx`;
  async function downloadReport(reportDto: ReportDto) {
    onClose();
    const blob = await convertBase64ToBlob(
      reportDto.base64File,
      reportDto.fileType
    );
    downloadBlobObject(blob, fileName);
  }
  const handleDateTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setSelectedDateType(e.target.value);
      const date = new Date();
      switch (e.target.value) {
        case "As of Date":
          formik.setFieldValue("startDate", "");
          break;
        case "Current Financial Year":
          formik.setFieldValue("startDate", `${date.getFullYear()}-04-01`);
          formik.setFieldValue("endDate", `${date.getFullYear() + 1}-03-31`);
          break;
        case "Previous Financial Year":
          formik.setFieldValue("startDate", `${date.getFullYear() - 1}-04-01`);
          formik.setFieldValue("endDate", `${date.getFullYear()}-03-31`);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="">
      <div className="p-8 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          {data?.headerName}
          <span
            className="cursor-pointer"
            onClick={() => {
              onClose();
            }}
          >
            X
          </span>
        </h6>
      </div>
      <VStack className="w-full gap-2 px-8 py-4 ">
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Date Type</Label>
            <Select
              options={dateTypes}
              onChange={(e) => handleDateTypeChange(e)}
              value={selectedDateType}
            />
          </div>
          {selectedDateType !== "As of Date" && (
            <div className="flex-1">
              <Label
                className={`text-sm font-normal ${
                  selectedDateType === "As of Date" ? "cursor-not-allowed" : ""
                }`}
              >
                {data?.startDate}
              </Label>
              <Input
                className={` ${
                  selectedDateType === "As of Date" ? "cursor-not-allowed" : ""
                }`}
                type="date"
                value={formik.values.startDate}
                onBlur={formik.handleBlur}
                disabled={selectedDateType === "As of Date"}
                onChange={(e) => {
                  if (!e.target.value) return;
                  formik.setFieldValue("startDate", e.target.value);
                }}
              />
            </div>
          )}
          <div className="flex-1">
            <Label className={"text-sm font-normal"}>{data?.endDate}</Label>
            <Input
              type="date"
              value={formik.values.endDate}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (!e.target.value) return;
                formik.setFieldValue("endDate", e.target.value);
              }}
            />
          </div>
          {selectedDateType === "As of Date" && <div className="flex-1"></div>}
        </HStack>
        <HStack className="pt-2 items-center gap-2 before:border-b-2 before:border-dashed before:m-auto after:flex-1 after:border-dashed after:content-[''] after:border-b-2">
          <ButtonPrimary1
            onClick={() => {
              if (showAdvanceFilters) {
                formik.resetForm();
              }
              setShowAdvanceFilters(!showAdvanceFilters);
            }}
            className="flex flex-row bg-transparent border border-solid cursor-pointer border-orange-501 hover:bg-transparent"
          >
            Advance Filters
            {showAdvanceFilters ? (
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded "
                className=""
              />
            ) : (
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded "
                className=""
              />
            )}
          </ButtonPrimary1>
        </HStack>
        {showAdvanceFilters ? (
          <VStack className="w-full gap-2 py-4">
            {data?.name !== "Liquidity Report" &&
            data?.name !== "BuyBack Report" ? (
              <HStack className="gap-8">
                <div className="flex-1">
                  <Label className={"text-sm font-normal"}>Grant states</Label>
                  <MultiSelectV2
                    _className="w-full"
                    multiple={true}
                    placeholder={"--Select states--"}
                    options={grantStates}
                    handleSelection={(e) => {
                      formik.setFieldValue("grantStates", e);
                    }}
                    optionValues={formik.values.grantStates || []}
                  />
                </div>

                <div className="flex-1">
                  <Label className={"text-sm font-normal"}>
                    Employment Status
                  </Label>
                  <MultiSelectV2
                    _className="w-full"
                    multiple={true}
                    placeholder={"--Select status--"}
                    options={employmentStates}
                    handleSelection={(e) => {
                      formik.setFieldValue("employeeStates", e);
                    }}
                    optionValues={formik.values.employeeStates || []}
                  />
                </div>
              </HStack>
            ) : (
              <HStack className="gap-8">
                <div className="flex-1">
                  <Label className={"text-sm font-normal"}>Offer states</Label>
                  <MultiSelectV2
                    _className="w-full"
                    multiple={true}
                    placeholder={"--Select states--"}
                    options={offerStates}
                    handleSelection={(e) => {
                      formik.setFieldValue("offerStates", e);
                    }}
                    optionValues={formik.values.offerStates || []}
                  />
                </div>
                <div className="flex-1">
                  <Label className={"text-sm font-normal"}>Event Names</Label>

                  <MultiSelectV2
                    _className="w-full"
                    multiple={true}
                    placeholder={"--Select events--"}
                    options={
                      data?.name === "Liquidity Report"
                        ? liquidityEvents
                        : buyBackEvents
                    }
                    handleSelection={(e) => {
                      const eventIds = events
                        .filter((ev) => e.includes(ev.eventName))
                        .map((ev) => ev.id);
                      formik.setFieldValue("eventIds", eventIds);
                    }}
                    optionValues={events
                      .filter((p) =>
                        formik.values.eventIds?.includes(p.id || "")
                      )
                      .map((p) => p.eventName)}
                  />
                </div>
              </HStack>
            )}
            <HStack className="gap-8">
              <div className="flex-1">
                <Label className={"text-sm font-normal"}>Plan Names</Label>

                <MultiSelectV2
                  _className="w-full"
                  multiple={true}
                  placeholder={"--Select plans--"}
                  options={planOptions}
                  handleSelection={(e) => {
                    const planIds = plans
                      .filter((p) => e.includes(p.planName))
                      .map((p) => p.esopPlanId);
                    formik.setFieldValue("planIds", planIds);
                  }}
                  optionValues={plans
                    .filter((p) =>
                      formik.values.planIds?.includes(p.esopPlanId)
                    )
                    .map((p) => p.planName)}
                />
              </div>
              {(data?.name === "Financial Report" ||
                data?.name === "Grant Report") && (
                <div className="flex-1">
                  <Label className={"text-sm font-normal"}>
                    {data?.name === "Grant Report"
                      ? "Transaction CutOff Date"
                      : "Vesting CutOff Date"}
                  </Label>
                  <Input
                    type="date"
                    value={formik.values.vestingCutOffDate}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (!e.target.value) return;
                      formik.setFieldValue("vestingCutOffDate", e.target.value);
                    }}
                  />
                </div>
              )}
            </HStack>
            {data?.name === "Financial Report" && (
              <HStack className="gap-8">
                <div className="flex-1">
                  <Box className="flex flex-row-reverse items-center justify-between">
                    <SwitchButton
                      className="mt-4 ml-4 mr-4"
                      value={formik.values.showFutureVesting || false}
                      label="Show Future vestings as zero"
                      onClick={() => {
                        formik.setFieldValue(
                          "showFutureVesting",
                          !formik.values.showFutureVesting
                        );
                      }}
                    />
                  </Box>
                </div>
                <div className="flex-1">
                  <Box className="flex flex-row-reverse items-center justify-between">
                    <SwitchButton
                      className="mt-4 ml-4 mr-4"
                      value={formik.values.considerVestedForOffered || false}
                      label="Consider Offered Grants as Vested"
                      onClick={() => {
                        formik.setFieldValue(
                          "considerVestedForOffered",
                          !formik.values.considerVestedForOffered
                        );
                      }}
                    />
                  </Box>
                </div>
              </HStack>
            )}
            {data?.name === "Financial Report" && formik.values.endDate && (
              <HStack className="gap-8 pt-4">
                <div className="flex-1">
                  <Box className="flex flex-row-reverse items-center justify-between">
                    <SwitchButton
                      className="mt-4 ml-4 mr-4"
                      value={
                        formik.values.changeEmploymentStatusAsPerCutoffDate ||
                        false
                      }
                      label="Employment Status as on End Date"
                      onClick={() => {
                        formik.setFieldValue(
                          "changeEmploymentStatusAsPerCutoffDate",
                          !formik.values.changeEmploymentStatusAsPerCutoffDate
                        );
                      }}
                    />
                  </Box>
                </div>
                <div className="flex-1"></div>
              </HStack>
            )}
          </VStack>
        ) : (
          <></>
        )}
        <HStack className="justify-end px-4 py-6 mt-7">
          <ButtonPrimary
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading ? (
              `Generate`
            ) : (
              <>
                Please Wait...
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </>
            )}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </div>
  );
};

export default ReportDialog;
