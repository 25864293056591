export type InitiateResignationDto = {
  employeeId: string;
  resignationDate: string;
  personalEmail: string;
  grantTableDetails: GrantStatusForResignation[];
  saveDetails: boolean;
};

export type GrantDetailsForExercise = {
  id: string;
  identifier: string;
  esopPlanId: string;
  planName: string;
  grantDate: string;
  strikePrice: number;
  cashedOut: number;
  stockExercised: number;
  forfeited: number;

  exerciseType: string;
  noOfOptionsToExercise: number;
  date: string;
  fairMarketValue: number;
};

export type GrantDetailsForExtendExercise = {
  id: string;
  identifier: string;
  esopPlanId: string;
  planName: string;
  grantDate: string;
  strikePrice: number;
  cashedOut: number;
  stockExercised: number;
  forfeited: number;

  optionsGranted: number;
  optionsVested: number;
  optionsAvailableForExercise: number;
  exerciseDate: string;
  noOfOptionsToExercise: number;

  newExpiryDate: string;
  currentExpiryDate: string;
};

export type GrantStatusForResignation = {
  id: string;
  identifier: string;
  esopPlanId: string;
  planName: string;
  grantDate: string;
  strikePrice: number;
  cashedOut: number;
  stockExercised: number;
  forfeited: number;

  optionsGranted: number;
  optionsVested: number;
  nextVestingDate: string;
  optionsAvailableForExercise: number;
  optionsExercisedDuringResignation: number;
  exerciseDate: string;

  noOfVestingEventsLeft: number;
  vestingPauseDate: string;
  pausedOptions: number;

  exerciseType: string;
  noOfOptionsToExercise: number;
  date: string;
  fairMarketValue: number;

  newExpiryDate: string;
  currentExpiryDate: string;
};

export type PauseVestingDetails = {
  enablePauseVesting: boolean;
  pauseVestingDate: string;
};

export type LastWorkingDayDetails = {
  setLastWorkingDay: boolean;
  lastWorkingDate: string;
  lapsedImmediately: boolean;
};

export type AccelerateVestingDetails = {
  enableAccelerateVesting: boolean;
};

export type ExerciseOptionsDetails = {
  allowExercise: boolean;
  totalOptionsToExercise: number;
  exerciseType: string;
  exerciseDate: string;
  fairMarketValue: number;
  getPreview: boolean;
  validatePreview: boolean;
  exerciseOptions: boolean;
  grantDetailsForExercises: GrantDetailsForExercise[];
};

export type ExtendExerciseDetails = {
  allowExtendExercise: boolean;
  grantDetailsForExercises: GrantDetailsForExtendExercise[];
};

export type FAFLetterDetails = {
  generateLetter: boolean;
  grantsStatus: GrantDetailsForExtendExercise[];

  uploadFAFLetter: boolean;
  base64File: string;
  fileName: string;
  fileContentType: string;
  sendFAFLetterToEmployees: boolean;
  emailAddress: string;
  enableAcceptanceWindow: boolean;
  fafLetterStatus: FafLetterStatus;

  sendLetterForApproval: boolean;
  signatureBase64: string;
  saveDetails: boolean;

  settlementLetterUrl: string;
  generationTime: Date;
};

export type ResignationDto = {
  initiationDetails: InitiateResignationDto;
  pauseVestingDetails: PauseVestingDetails;
  lastWorkingDayDetails: LastWorkingDayDetails;
  exerciseOptionsDetails: ExerciseOptionsDetails;
  extendExerciseDetails: ExtendExerciseDetails;
  fafLetterDetails: FAFLetterDetails;
  finalGrantsStatus: GrantStatusForResignation[];
};

export enum FafLetterStatus {
  AcceptanceInitiated = "AcceptanceInitiated",
  AcceptancePending = "AcceptancePending",
  Accepted = "Accepted",
}

export type UploadEmployeeDto = {
  file: string;
  headerRow: number;
  rawHeaderData?: RawHeaderData;
  mapperData?: MapperData;
  useExitingTemplate?: boolean;
  exitingMapperConfigId?: string;
};
export interface RawHeaderData {
  headersInFile: string[];
  headersInSystem: string[];
}
export type EmployeeFields = [
  "address",
  "manager",
  "contact",
  "pan",
  "department",
  "designation",
  "compensationDetails",
  "residentialStatus",
  "nationality",
  "workingCountry",
  "legalName",
  "name",
  "status",
  "type",
  "email",
  "hrId",
  "isLoginProvided",
  "dateOfJoining",
  "resignationDate",
  "lastDay",
  "companyId",
  "previousEmployeeId"
];

export interface MapperData {}
